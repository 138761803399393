import React, { useEffect, useState } from "react";
import FolderDocuments from "../createEditCompany/FolderDocuments";
import FolderCard from "../../../commons/FolderCard";
import { AddButton } from "../../../components/micro";
import { useDispatch, useSelector } from "react-redux";
import { getFileList } from "../../../redux/folder&documents/slice";
import moment from "moment";
import AddFolderModal from "./EditAddFolder";
import RippleLoader from "../../../commons/RippleLoader";
import { getCompanyById } from "../../../redux/companies/slice";

const Documents = ({ companyByIdData, onLoading }) => {
  const dispatch = useDispatch();
  const [showPDFDocuments, setShowPDFDocuments] = useState(false);
  const [folderDetails, setFolderDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  // Selector
  const { fileList } = useSelector((store) => ({
    // files
    fileList: store?.fileData?.getFileListStatus?.data,
  }));

  const companyId = companyByIdData?.id;

  // get files
  useEffect(() => {
    if (companyId) {
      fetchFileList();
    }
  }, [dispatch, companyId]);

  const fetchFileList = async () => {
    setLoading(true);
    await dispatch(getFileList({ id: companyId }));
    setLoading(false);
  };

  const handleCreateFolder = async () => {
    setIsModalOpen(false);
    await fetchFileList();
  };

  return (
    <>
      {showPDFDocuments ? (
        <FolderDocuments
          folderDetails={folderDetails}
          setShowPDFDocuments={setShowPDFDocuments}
          setFolderDetails={setFolderDetails}
          companyByIdData={companyByIdData}
        />
      ) : (
        <>
          {loading || onLoading ? (
            <div className="flex justify-center items-center h-full">
              <RippleLoader />
            </div>
          ) : (
            <>
              <div className="grid 2xl:grid-cols-8 grid-cols-6 gap-4 bg-light_secondary rounded-lg px-8 py-5">
                {fileList?.data?.map((element, index) => {
                  return (
                    <FolderCard
                      folderName={element?.name}
                      folderId={element?.id}
                      lastUpdated={moment(element?.updated_at).format(
                        "DD/MM/YYYY hh:mmA"
                      )}
                      companyByIdData={companyByIdData}
                      key={index}
                      onClick={() => {
                        setFolderDetails(element);
                        setShowPDFDocuments(true);
                      }}
                      onFolderDeleteSuccess={fetchFileList}
                    />
                  );
                })}
              </div>
              <AddButton
                value="Add Folder"
                onClick={() => setIsModalOpen(true)}
              />
            </>
          )}
        </>
      )}
      <AddFolderModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCreate={handleCreateFolder}
        companyByIdData={companyByIdData}
        folderName={folderDetails.name}
        folderId={folderDetails.id}
      />
    </>
  );
};

export default Documents;
