import React from "react";
import { PDFDoc, User } from "../assets/svg/AllSvg";
import moment from "moment/moment";
import Action from "./Action";

const ReceiveMsg = ({ isActive, img, element }) => {
  return (
    <>
      <div className="flex items-start space-x-3">
        <div className="relative w-9 h-9 bg-grey rounded-full flex justify-center items-center">
          <User pathClass={"fill-white"} fillOpacity={1} />
          {isActive && (
            <span className="absolute bottom-0 right-0 border-2 border-white w-2.5 h-2.5 bg-green-600 rounded-full"></span>
          )}
          {img && (
            <img
              src={img}
              alt="profile"
              className="w-full h-full object-cover rounded-full"
            />
          )}
        </div>
        <div className="space-y-2">
          <div className="messageContent max-w-52 flex flex-col items-start justify-start space-y-2">
            {element?.type === "text" ? (
              <p className="messageText bg-sub_grey px-3 py-2">
                {element.content}
              </p>
            ) : (
              <>
                {element?.documents?.map((subElement) => {
                  return (
                    <>
                      {subElement?.document?.document_type === "doc" ? (
                        <div
                          className={
                            "card bg-light_secondary flex justify-between items-center max-w-60 w-full relative"
                          }
                        >
                          <div className="flex items-center w-full">
                            <PDFDoc className="mr-3" />
                            <p className="text-black font-normal max-w-full text-sm truncate">
                              {subElement?.document?.name}
                            </p>
                          </div>
                          <button type="button">
                            <Action
                              optionList={
                                <>
                                  <a
                                    href={subElement?.document?.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                  >
                                    View
                                  </a>
                                  <a
                                    href={subElement?.document?.url}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                  >
                                    Download
                                  </a>
                                </>
                              }
                            />
                          </button>
                        </div>
                      ) : (
                        <div className="relative">
                          <img
                            src={subElement?.document?.url}
                            alt="img"
                            className="w-full rounded h-52"
                          />
                          <div className="absolute top-1 right-1">
                            <Action
                              className={"bg-white"}
                              optionList={
                                <>
                                  <a
                                    href={subElement?.document?.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                  >
                                    View
                                  </a>
                                  <a
                                    href={subElement?.document?.url}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                  >
                                    Download
                                  </a>
                                </>
                              }
                            />
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </>
            )}
          </div>

          <p className="text-grey text-xs text-right">
            {moment(element.updated_at).format("dd/mm/YYYY HH:MM")}
          </p>
        </div>
      </div>
    </>
  );
};

export default ReceiveMsg;
