import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../commons/Breadcrumb";
import Tab from "../../../commons/Tab";
import CompanyInformation from "./CompanyInformation";
import Organisation from "./Organisation";
import Documents from "./Documents";
import SalesTransaction from "./SalesTransaction";
import { useDispatch, useSelector } from "react-redux";
import {
  companyData,
  getCompanyById,
  getSalesByCompanyId,
} from "../../../redux/companies/slice";
import { useLocation } from "react-router-dom";
import Others from "./Others";

const CompanyProfile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const CompanyData = location?.state?.element;

  // Selector
  const { companyByIdData, loading, CompanyInfoData } = useSelector(
    (store) => ({
      // Company By Id
      companyByIdData: store?.companiesData?.getCompanyByIdStatus?.data?.data,
      // Company Data
      CompanyInfoData: store?.companiesData?.companyDataStatus?.data?.data,
      // loading
      loading: store?.companiesData?.loading,
    })
  );

  const tabs = [
    {
      label: "Company Information",
      content: (
        <CompanyInformation
          companyByIdData={companyByIdData}
          onLoading={loading}
        />
      ),
    },
    {
      label: "Organisation",
      content: (
        <Organisation companyByIdData={companyByIdData} onLoading={loading} />
      ),
    },
    {
      label: "Documents",
      content: (
        <Documents companyByIdData={companyByIdData} onLoading={loading} />
      ),
    },
    {
      label: "Sales & Transaction",
      content: (
        <SalesTransaction
          companyByIdData={companyByIdData}
          onLoading={loading}
        />
      ),
    },
    {
      label: "Others",
      content: (
        <Others
          companyByIdData={companyByIdData}
          CompanyData={CompanyData}
          CompanyInfoData={CompanyInfoData}
          onLoading={loading}
        />
      ),
    },
  ];

  useEffect(() => {
    if (CompanyData?.id) {
      dispatch(getCompanyById(CompanyData?.id));
    }
  }, [CompanyData]);

  useEffect(() => {
    dispatch(companyData());
  }, [dispatch]);

  return (
    <div className="space-y-5">
      <div>
        <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
          View Company
        </h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbActive={"View Company"}
          breadCrumbParent2={"Companies"}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>
      <div className="w-full bg-white border rounded py-5 px-6">
        <h1 className="font-ebGaramond font-semibold text-4xl mb-5">
          Company Profile
        </h1>
        <Tab tabs={tabs} btnClass="w-full font-semibold mb-5 h-full" />
      </div>
    </div>
  );
};

export default CompanyProfile;
