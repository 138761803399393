import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginApiAdmin } from "../../redux/auth/slice";
import RippleLoader from "../../commons/RippleLoader";
import { Logo, authBgImage } from "../../assets/image";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputWithLabel } from "../../components/micro";
import { getToken, messaging } from "../../commons/Firebase";
import { v4 as uuidv4 } from "uuid";

// Device Type
const getDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (/mobile/i.test(userAgent)) {
    return "Mobile";
  } else if (/tablet|ipad|playbook|silk|android(?!.*mobi)/i.test(userAgent)) {
    return "Tablet";
  }
  return "Desktop";
};

// Device Name
const getDeviceName = () => {
  const userAgent = navigator.userAgent;
  // Simple regex to match device names (customize this as needed)
  if (userAgent.match(/iPhone/i)) return "iPhone";
  if (userAgent.match(/iPad/i)) return "iPad";
  if (userAgent.match(/Android/i)) return "Android Device";
  if (userAgent.match(/Windows/i)) return "Windows PC";
  if (userAgent.match(/Mac/i)) return "Mac";
  return "Unknown Device";
};

const getDeviceID = () => {
  let deviceID = localStorage.getItem("device_id");
  if (!deviceID) {
    deviceID = uuidv4();
    localStorage.setItem("device_id", deviceID);
  }
  return deviceID;
};

function Auth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Vapid_key = process.env.VITE_APP_VAPID_KEY;
  const [loginState, setLoginState] = useState(false);
  const [fcmToken, setFcmToken] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [deviceID, setDeviceID] = useState("");

  const { logingData, logingDataLoading, logingDataError } = useSelector(
    (store) => ({
      logingData: store?.authData?.loginStatus?.data?.data?.data,
      logingDataLoading: store?.authData?.loading,
      logingDataError: store?.authData?.error,
    })
  );

  useEffect(() => {
    if (!logingDataLoading && !logingDataError && loginState) {
      logingData ? navigate("/") : navigate("/");
    } else {
      navigate("/login");
    }
  }, [navigate, logingDataLoading, logingDataError, loginState, logingData]);

  useEffect(() => {
    setDeviceType(getDeviceType());
    setDeviceName(getDeviceName());
    setDeviceID(getDeviceID());
  }, [dispatch]);

  useEffect(() => {
    const requestPermission = async () => {
      try {
        await Notification.requestPermission();
        if (Notification.permission === "granted") {
          const token = await getToken(messaging, {
            vapidKey: Vapid_key,
          });
          setFcmToken(token);
        } else {
          console.log("Unable to get permission to notify.");
        }
      } catch (err) {
        console.log("Error while requesting permission ", err);
      }
    };

    requestPermission();
  }, []);

  const handleLogin = (values) => {
    dispatch(
      loginApiAdmin({
        fcm_token: fcmToken,
        device_type: deviceType,
        device_name: deviceName,
        device_id: deviceID,
        ...values,
      })
    ).then((res) => {
      setLoginState(false);
      if (res.type === "loginApiAdmin/fulfilled") {
        navigate("/");
      }
    });
  };

  return (
    <>
      <div className={`h-screen overflow-hidden relative`}>
        {logingDataLoading && <RippleLoader />}
        <div
          className={`flex items-center justify-center h-full  bg-no-repeat bg-cover bg-center ${
            logingDataLoading && ` hidden `
          } `}
          style={{
            backgroundImage: `url(${authBgImage})`,
          }}
        >
          <div className="absolute top-10 left-10 w-20 h-20">
            <img src={Logo} alt="logo" className="w-full h-full object-cover" />
          </div>
          <section className="w-full">
            <div className="max-w-xl w-full mx-auto">
              <div className="rounded-lg drop-shadow-lg bg-white/80 px-6 py-8 mx-4">
                <div className={"mb-8"}>
                  <p className="text-sm opacity-65 mb-2">Welcome!</p>
                  <h1 className="sm:text-2xl text-lg font-ebGaramond font-semibold mb-1">
                    Login to Ei Biz System
                  </h1>
                </div>
                {/* Login flow */}
                <Formik
                  initialValues={{
                    email: "admin@eibiz.co",
                    password: "Admin@123",
                    role: "admin",
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string().required("Please Enter Email"),
                    password: Yup.string()
                      .required("Please Enter Password")
                      .min(
                        8,
                        "Password is too short - should be 8 chars minimum."
                      )
                      .matches(
                        /[a-zA-Z]/,
                        "Password can only contain Latin letters."
                      ),
                  })}
                  onSubmit={(values) => {
                    handleLogin(values);
                    setLoginState(true);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="space-y-5">
                        {/* Email */}
                        <InputWithLabel
                          className={
                            errors.email && touched.email
                              ? ` input-error `
                              : ` border `
                          }
                          idFromName="email"
                          label="Email"
                          type="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          errors={errors.email && touched.email}
                          errorsText={errors.email}
                        />
                        {/* password */}
                        <div>
                          <div className="mb-1">
                            <InputWithLabel
                              className={
                                errors.password && touched.password
                                  ? ` input-error `
                                  : ` border `
                              }
                              idFromName="password"
                              label="Password"
                              type="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              errors={errors.password && touched.password}
                              errorsText={errors.password}
                            />
                          </div>
                          {/* forget password */}
                          <Link
                            to={"/reset-password"}
                            className="border-b border-secondary_grey"
                          >
                            forget password?
                          </Link>
                        </div>
                        <button
                          type="submit"
                          className="w-full block text-white bg-theme text-center text-sm border-2 border-transparent hover:bg-transparent hover:border-2 hover:border-theme hover:text-theme rounded transition duration-500 py-2 lg:px-7 px-4"
                        >
                          Log In
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Auth;
