import React, { useState } from "react";
import DebounceSearch from "../../commons/DebounceSearch";
import { FileDownload } from "../../assets/svg/AllSvg";
import Breadcrumb from "../../commons/Breadcrumb";
import CustomTable from "../../commons/CustomTable";
import { Badge } from "../../components/micro";

const ActivityLog = () => {
  const [searchValue, setSearchValue] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  // Table Data
  const ActivityList = {
    data: [
      {
        id: "01",
        user: "Name 1",
        user_role: "Finance",
        method: "get",
        uri: "admin/account",
        ip: "000.000.00.000",
        input: `{  “”s”:”Vadmin”}`,
        time_stamp: "22/02/2024 16:37:23",
      },
      {
        id: "02",
        user: "Name 2",
        user_role: "Operation",
        method: "get",
        uri: "admin/account",
        ip: "000.000.00.000",
        input: `{  “”s”:”Vadmin”}`,
        time_stamp: "22/02/2024 16:37:23",
      },
      {
        id: "03",
        user: "Name 3",
        user_role: "SuperAdmin",
        method: "post",
        uri: "admin/account",
        ip: "000.000.00.000",
        input: `{  “”s”:”Vadmin”}`,
        time_stamp: "22/02/2024 16:37:23",
      },
    ],
    current_page: 1,
    per_page: 10,
    last_page: 1,
    total: 3,
  };

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b font-semibold text-left bg-primary_shades_light whitespace-nowrap rounded-tl"
        >
          No.
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light"
        >
          User
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          User Role
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Method
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          URI
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          IP
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Input
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Time Stamp
        </th>
      </>
    );
  };
  // Table Row
  const DataRows = () => {
    return (
      <>
        {(ActivityList?.data || [])?.map((element, index) => (
          <tr
            className={"border-b text-sm hover:bg-secondary_light_shades"}
            key={element?.id}
          >
            <td className="px-5 py-3">{element?.id}</td>
            <td className="px-5 py-3">{element?.user}</td>
            <td className="px-5 py-3">
              <p className="max-w-[100px] truncate">{element?.user_role}</p>
            </td>
            <td className="px-5 py-3">
              <div className="flex justify-start">
                {
                  <Badge
                    className={`uppercase text-white ${
                      element?.method === "post"
                        ? "border-success bg-success"
                        : "border-theme bg-theme"
                    }`}
                    value={element?.method}
                  />
                }
              </div>
            </td>
            <td className="px-5 py-3">{element?.uri}</td>
            <td className="px-5 py-3">{element?.ip}</td>
            <td className="px-5 py-3">{element?.input}</td>
            <td className="px-5 py-3">{element?.time_stamp}</td>
          </tr>
        ))}
      </>
    );
  };
  return (
    <div className="space-y-5">
      <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
        Activities Log
      </h6>
      <Breadcrumb
        breadCrumbParent={"Home"}
        breadCrumbActive={"Activities Log"}
        breadCrumbTitleKey={"/"}
        classes=""
      />
      <div className="flex justify-between items-center space-x-3">
        <DebounceSearch
          search={searchValue}
          handleChange={setSearchValue}
          placeholder={"Search..."}
        />
        <button className="btn-outline flex items-center uppercase">
          <FileDownload width="14" height="17" className="mr-3" />
          Export CSV
        </button>
      </div>
      <div className="w-full bg-white border rounded">
        <CustomTable
          columnHeaders={<ColumnHeaders />}
          dataRows={<DataRows />}
          data={ActivityList}
          overflowVisibleLg={true}
          // loader
          loading={false}
          showPagination={true}
          // showLimit
          setPaginationCurrentLimit={setPageLimit}
          paginationCurrentLimit={pageLimit}
          // paginationData
          total={ActivityList?.total}
          current={pageCount}
          paginationData={(crPage) => setPageCount(crPage)}
        />
      </div>
    </div>
  );
};

export default ActivityLog;
