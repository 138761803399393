import React from "react";
import Breadcrumb from "../../commons/Breadcrumb";
import { Logo } from "../../assets/image";
import { FileDownload } from "../../assets/svg/AllSvg";
import PdfComponent from "./PdfComponent";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const SalesView = () => {
  const downloadPdf = () => {
    const input = document.getElementById("pdfContent");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("receipt.pdf");
    });
  };
  return (
    <div className="space-y-5">
      <div>
        <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
          “Company Name” Transaction
        </h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbActive={"“Company Name” Transaction"}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>
      <div className="bg-white border rounded py-5 px-6">
        <h1 className="font-ebGaramond text-4xl mb-5">“Company Name”</h1>
        <div className="card space-y-5">
          <div className="flex justify-between items-center">
            <div className="w-20 h-20">
              <img
                src={Logo}
                alt="logo"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="max-w-60 space-y-1">
              <p>EiBiz</p>
              <p>
                <span className="font-semibold">Address: </span>123 Seng Kang
                #01-03 S123456
              </p>
              <p>
                <span className="font-semibold">Contact No: </span> 9090 9090
              </p>
            </div>
          </div>
          <div>
            <p className="font-ebGaramond text-4xl mb-5">Receipt Summary</p>
            <p>ID: #0001</p>
            <p>Customer Name: Customer Name 1</p>
            <p className="mb-3">Customer Name: Customer Pte Ltd</p>
            <p>Date: 17/07/2023 | 14:00 PM</p>
          </div>
          <table
            className={`w-full text-sm overflow-x-auto min-w-[400px] custom-scroll`}
          >
            {/* column headers */}
            <thead className="text-sm text-gray-700 bg-secondary_light_shades font-semibold ">
              <tr>
                <th
                  scope="col"
                  className="px-5 py-3 border-b font-semibold text-left bg-secondary_light_shades whitespace-nowrap rounded-tl-md"
                >
                  No.
                </th>
                <th
                  scope="col"
                  className="px-5 py-2 border-b font-semibold text-left bg-secondary_light_shades"
                >
                  Request
                </th>
                <th
                  scope="col"
                  className="px-5 py-2 border-b font-semibold bg-secondary_light_shades"
                >
                  Price
                </th>
              </tr>
            </thead>

            {/* table body */}
            <tbody className="relative">
              <tr className="border-b text-sm">
                <td className="px-5 py-3">01</td>
                <td className="px-5 py-3">
                  Change of Registered Company Address
                </td>
                <td className="text-center px-5 py-3">$300.00</td>
              </tr>
            </tbody>
          </table>
          <div className="text-right !mb-10">
            <p>Subtotal: $300.00</p>
            <p>Total: $300.00</p>
          </div>
          <p className="!mb-10">TnC Here</p>
          <div className="flex justify-end">
            <button
              className="btn-theme flex items-center"
              onClick={downloadPdf}
            >
              <FileDownload
                width="14"
                height="17"
                className="mr-2"
                fillColor="fill-white"
              />
              PDF - summary
            </button>
          </div>
        </div>
      </div>
      <div>
        <PdfComponent />
      </div>
    </div>
  );
};

export default SalesView;
