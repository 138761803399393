import React, { useEffect } from "react";
import { Close, FileDownload } from "../../../../assets/svg/AllSvg";
import { Logo } from "../../../../assets/image";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PdfComponent from "./PdfComponent";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionsById } from "../../../../redux/companies/slice";
import RippleLoader from "../../../../commons/RippleLoader";
import moment from "moment";

const ViewSales = ({ setViewSales, salesData, companyName }) => {
  const dispatch = useDispatch();

  // Selector
  const { getSalesByCompanyIdData, loading } = useSelector((store) => ({
    getSalesByCompanyIdData:
      store?.companiesData?.getTransactionsByIdStatus?.data?.data,
    loading: store?.companiesData?.getTransactionsByIdStatus?.loading,
  }));

  useEffect(() => {
    if (salesData?.id) {
      dispatch(getTransactionsById(salesData?.id));
    }
  }, [dispatch, salesData]);

  const downloadPdf = () => {
    const input = document.getElementById("pdfContent");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("receipt.pdf");
    });
  };
  return (
    <>
      <div
        className="model"
        onClick={(e) => {
          setViewSales(false);
        }}
      >
        <div
          className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-3xl min-h-40 py-6 px-6 mx-auto relative"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="flex items-center justify-between w-full mb-4">
            <h2 className="text-3xl font-ebGaramond text-center">
              “{companyName}”
            </h2>
            <button onClick={() => setViewSales(false)}>
              <Close />
            </button>
          </div>
          {loading ? (
            <RippleLoader />
          ) : (
            <div className="card w-full max-h-[calc(100vh-200px)] overflow-auto space-y-5">
              <div className="flex justify-between">
                <img className="h-20 w-20" src={Logo} alt="logo" />
                <div className="text-right text-xs">
                  <p className="font-semibold mb-2">EiBiz Management Pte Ltd</p>
                  <p>One-Stop Corporate Solutions Provider</p>
                  <p>Co. Reg. No: 00000OK</p>
                  <p>HP: 0000000 Email: admin@ei-biz.com.sg</p>
                  <p>wedsite: www.ei-biz.com.sg</p>
                  <p>
                    Address: 140 Paya Lebar Rode #06-05 AZ@Paya Lebar Singapore
                    409015
                  </p>
                </div>
              </div>
              <div>
                <p className="font-ebGaramond text-2xl mb-3">Receipt</p>
                <p className="text-xs">
                  {" "}
                  Receipt ID: {getSalesByCompanyIdData?.reference_number}
                </p>
                <p className="text-xs">
                  Customer Name: {getSalesByCompanyIdData?.user?.full_name}
                </p>
                <p className="text-xs">Company Name: {companyName}</p>
                <p className="text-xs pt-3">
                  Date:{" "}
                  {moment(getSalesByCompanyIdData?.created_at).format(
                    "DD/MM/YYYY"
                  )}
                </p>
              </div>

              <table
                className={`w-full text-sm overflow-x-auto min-w-[400px] custom-scroll mb-5`}
              >
                {/* column headers */}
                <thead className="text-sm text-gray-700 bg-secondary_light_shades font-semibold ">
                  <tr>
                    <th
                      scope="col"
                      className="px-5 py-3 border-b font-semibold text-left bg-secondary_light_shades whitespace-nowrap rounded-tl-md"
                    >
                      No.
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-2 border-b text-left font-semibold bg-secondary_light_shades"
                    >
                      Items
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-2 border-b font-semibold bg-secondary_light_shades"
                    >
                      Price
                    </th>
                  </tr>
                </thead>

                {/* table body */}
                <tbody className="relative">
                  <tr className="border-b text-sm">
                    <td className="px-5 py-3">01</td>
                    <td className="px-5 py-3">
                      {moment(getSalesByCompanyIdData?.item?.start_date).format(
                        "YYYY"
                      )}{" "}
                      Yearly Subscription{" "}
                    </td>
                    <td className="text-center px-5 py-3">
                      ${getSalesByCompanyIdData?.detail?.sub_total}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="text-right text-xs space-y-1">
                <p>Subtotal: ${getSalesByCompanyIdData?.detail?.sub_total}</p>
                <p>
                  GST {getSalesByCompanyIdData?.detail?.gst}%: $
                  {getSalesByCompanyIdData?.detail?.gst_amount}
                </p>
                <p>Total: ${getSalesByCompanyIdData?.detail?.total}</p>
              </div>
              <div>
                <p className="text-xs">TnC Here</p>
              </div>
              <div className="flex justify-end gap-4 w-full pt-5">
                <button
                  type="button"
                  className="btn-theme flex space-x-2 items-center uppercase"
                  onClick={downloadPdf}
                >
                  <FileDownload
                    width="14"
                    height="17"
                    className="mr-2"
                    fillColor="fill-white"
                  />{" "}
                  Download PDF
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <PdfComponent
        getSalesByCompanyIdData={getSalesByCompanyIdData}
        companyName={companyName}
      />
    </>
  );
};

export default ViewSales;
