import React, { useEffect, useRef, useState } from "react";
import DebounceSearch from "../../commons/DebounceSearch";
import { UpArrow, User } from "../../assets/svg/AllSvg";
import { authBgImage } from "../../assets/image";
import AvatarUser from "../../commons/AvatarUser";
import { io } from "socket.io-client";
import moment from "moment";

const ClientsList = ({ setNewChatName }) => {
  const [searchValue, setSearchValue] = useState("");
  const [showClients, setShowClients] = useState(true);
  const [showGeneralChat, setShowGeneralChat] = useState(true);
  const [activeTab, setActiveTab] = useState("active");
  const [getClients, setGetClients] = useState({});
  const adminId = sessionStorage.getItem("adminId");
  const socketRef = useRef();

  const tabs = [{ label: "active" }, { label: "archive" }];

  const accessAdminToken = sessionStorage.getItem("accessToken");

  // Start Socket
  useEffect(() => {
    socketRef.current = io("http://103.47.29.22:3000/", {
      auth: {
        token: accessAdminToken,
      },
    });

    socketRef.current.on("chatList", (data) => {
      setGetClients(data?.data);
    });

    socketRef.current.on("error", (err) => {
      console.log(err);
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [searchValue]);

  useEffect(() => {
    socketRef.current.emit("getChatList", {
      search: searchValue,
      status: activeTab,
    });
  }, [searchValue]);

  return (
    <div className="space-y-3 pr-4 border-r">
      <p className="font-ebGaramond text-theme text-xl">Messages</p>
      <div className="flex border border-theme rounded overflow-hidden">
        {tabs.map((tab, index) => (
          <button
            key={tab.label}
            className={`py-2 px-4 uppercase text-center text-sm text-theme font-semibold w-full transition duration-300 ${
              activeTab === tab.label ? "bg-theme/30" : ""
            } ${index === 0 && "border-r border-theme"}`}
            onClick={() => setActiveTab(tab.label)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <DebounceSearch
        search={searchValue}
        handleChange={setSearchValue}
        placeholder={"Search..."}
      />
      <button
        className="text-theme flex items-center justify-between w-full"
        onClick={() => {
          setShowClients(!showClients);
        }}
      >
        <p>Clients</p>
        {!showClients ? <UpArrow /> : <UpArrow className="rotate-180" />}
      </button>
      {showClients && (
        <div className="max-h-[31rem] overflow-auto">
          {getClients?.users?.map((element, index) => {
            return (
              <>
                {element?.model?.id == adminId ? (
                  <AvatarUser
                    className={
                      "hover:bg-light_secondary_shades rounded-xl cursor-pointer px-4 py-3"
                    }
                    isActive={element?.isActive}
                    img={element?.receiver?.profile_path}
                    name={element?.receiver?.full_name ?? "-"}
                    company_name={element?.receiver?.name}
                    date={moment(element?.updated_at).format(
                      "DD/MM/YYYY HH:MM"
                    )}
                    key={index}
                    onClick={() => setNewChatName(element)}
                  />
                ) : (
                  <AvatarUser
                    className={
                      "hover:bg-light_secondary_shades rounded-xl cursor-pointer px-4 py-3"
                    }
                    isActive={element?.isActive}
                    img={element?.model?.profile_path}
                    name={element?.model?.full_name ?? "-"}
                    company_name={element?.model?.name}
                    date={moment(element?.updated_at).format(
                      "DD/MM/YYYY HH:MM"
                    )}
                    key={index}
                    onClick={() => setNewChatName(element)}
                  />
                )}
              </>
            );
          })}
        </div>
      )}
      {/* <button
        className="text-theme flex items-center justify-between w-full"
        onClick={() => {
          setShowGeneralChat(!showGeneralChat);
        }}
      >
        <p>General Chat</p>
        {!showGeneralChat ? <UpArrow /> : <UpArrow className="rotate-180" />}
      </button>
      {showGeneralChat && (
        <div className="max-h-56 overflow-auto">
          {getClients?.groups?.map((element, index) => {
            return (
              <>
                {element?.model?.id == adminId ? (
                  <AvatarUser
                    className={
                      "hover:bg-light_secondary_shades rounded-xl cursor-pointer px-4 py-3"
                    }
                    isActive={element?.isActive}
                    img={element?.receiver?.profile_path}
                    name={element?.receiver?.full_name}
                    company_name={element?.receiver?.name}
                    date={moment(element?.updated_at).format(
                      "DD/MM/YYYY HH:MM"
                    )}
                    key={index}
                    onClick={() => setNewChatName(element)}
                  />
                ) : (
                  <AvatarUser
                    className={
                      "hover:bg-light_secondary_shades rounded-xl cursor-pointer px-4 py-3"
                    }
                    isActive={element?.isActive}
                    img={element?.model?.profile_path}
                    name={element?.model?.full_name}
                    company_name={element?.model?.name}
                    date={moment(element?.updated_at).format(
                      "DD/MM/YYYY HH:MM"
                    )}
                    key={index}
                    onClick={() => setNewChatName(element)}
                  />
                )}
              </>
            );
          })}
        </div>
      )} */}
    </div>
  );
};

export default ClientsList;
