import React, { useEffect, useState } from "react";
import { Folder } from "../assets/svg/AllSvg";
import Action from "./Action";
import AddFolderModal from "../pages/companies/viewCompany/EditAddFolder";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteFile } from "../redux/folder&documents/slice";
import ConfirmationModel from "./ConfirmationModel";

const FolderCard = ({
  folderName,
  lastUpdated,
  onClick,
  folderId,
  companyByIdData,
  onFolderDeleteSuccess,
}) => {
  const dispatch = useDispatch();
  const [folder_id, setFolder_id] = useState();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleActionClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    setFolder_id(folderId);
  }, [folderId]);

  const handleRenameClick = () => {
    setEditModalOpen(true);
  };

  const handleDeleteClick = () => {
    setConfirmModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    try {
      const res = await dispatch(deleteFile(folderId));
      if (res.type === "deleteFile/fulfilled") {
        toast.success(res.payload.message);
      }
    } finally {
      setIsLoading(false);
      setConfirmModalOpen(false);
    }
  };

  const handleEditSuccess = async () => {
    setIsLoading(true);
    try {
      await onFolderDeleteSuccess();
      toast.success("Document edited successfully!");
    } catch (error) {
      toast.error("Failed to refresh documents.");
    } finally {
      setIsLoading(false);
      setEditModalOpen(false);
    }
  };

  return (
    <>
      <div
        className="relative bg-white !rounded-xl text-center cursor-pointer items-center justify-center flex flex-col shadow-2xl py-4 px-2"
        onClick={onClick}
      >
        <Folder className="mb-2" />
        <button
          className="absolute top-0 -right-2 p-2"
          onClick={handleActionClick}
        >
          <Action
            optionList={
              <>
                <button
                  className="flex gap-2 items-center w-full px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                  onClick={handleRenameClick}
                >
                  Rename Folder
                </button>
                <button
                  className="flex gap-2 items-center w-full px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                  onClick={handleDeleteClick}
                >
                  Delete
                </button>
              </>
            }
          />
        </button>
        <p className="mb-1">{folderName}</p>
        <p className="text-xs text-light_grey font-semibold leading-normal">
          Last Updated{" "}
        </p>
        <p className="text-xs text-light_grey font-semibold leading-normal">
          “{lastUpdated}”
        </p>
      </div>
      <AddFolderModal
        isOpen={isEditModalOpen}
        onClose={() => setEditModalOpen(false)}
        folderName={folderName}
        folderId={folder_id}
        companyByIdData={companyByIdData}
        onSuccess={handleEditSuccess}
      />
      {isConfirmModalOpen && (
        <ConfirmationModel
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={handleConfirmDelete}
          confirmText="Delete Document"
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default FolderCard;
