import React, { useEffect, useState } from "react";
import { BusinessBuilding } from "../../../../assets/svg/AllSvg";
import RegisteredOfficeAddress from "./RegisteredOfficeAddress";
import NewRegisteredOfficeAddress from "./NewRegisteredOfficeAddress";
import { Link } from "react-router-dom";

const CompanyAddress = ({
  companyId,
  addresses,
  business_registered_address_fee,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (addresses?.length) {
      setActiveTab(addresses?.length === 1 || !addresses?.length ? 0 : 1);
    }
  }, [addresses]);

  const tabs = [
    {
      text: "Use EiBiz's registered address & Digital Mailroom Service",
      id: 0,
      content: (
        <RegisteredOfficeAddress companyId={companyId} addresses={addresses} />
      ),
      price: business_registered_address_fee,
    },
    {
      text: "Use your own company address",
      link: "Eligibility Criteria",
      linkUrl:
        "https://www.acra.gov.sg/how-to-guides/setting-up-a-local-company/registered-office-address",
      id: 1,
      content: (
        <NewRegisteredOfficeAddress
          companyId={companyId}
          addresses={addresses}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="border-b border-secondary_grey pb-2 mb-5">
        <p className="text-md mb-1">Secondly,</p>
        <p className="font-ebGaramond text-2xl mb-3">
          We will need to know your Company address
        </p>
      </div>
      {/* tab head */}
      <div className="grid grid-cols-2 gap-5 mb-5">
        {tabs?.map((element) => {
          return (
            <div
              className={`p-4 ${
                activeTab === element?.id ? "bg-light_theme" : "bg-white"
              } rounded-lg border-2 space-y-3`}
              onClick={() => {
                setActiveTab(element?.id);
              }}
            >
              <div className="flex space-x-5">
                <BusinessBuilding />
                <div className="space-y-3 w-full">
                  <p>{element?.text}</p>
                  {element?.price && (
                    <div className="flex justify-between space-x-1 bg-light_theme px-4 py-3">
                      <p>Price</p>
                      <div>
                        <p className="text-sm text-gray-400">Annual Fees</p>
                        <p>${business_registered_address_fee}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {element?.link && (
                <Link
                  target="_blank"
                  to={element?.linkUrl}
                  className="text-theme border-b border-theme inline-block"
                >
                  {element?.link}
                </Link>
              )}
            </div>
          );
        })}
      </div>
      {/* tab content */}
      {tabs?.map((element) => activeTab === element?.id && element?.content)}
    </div>
  );
};

export default CompanyAddress;
