import React from "react";
import { Trash } from "../../../assets/svg/AllSvg";
import { Form, Formik } from "formik";
import ReactSelect from "react-select";
import * as Yup from "yup";
import { InputError } from "../../../commons/MicroComponents";

const AccountDetails = ({
  element,
  className,
  removeAccount,
  index,
  updateAccountDetailsList,
  GetCompany,
  loading,
}) => {
  const company_roleList = [
    { value: "foreign_director", label: "Foreign Director" },
    { value: "resident_director", label: "Local Director" },
    { value: "shareholder", label: "Shareholder" },
    {
      value: "foreign_director_shareholder",
      label: "Foreign Director & Shareholder",
    },
    {
      value: "resident_director_shareholder",
      label: "Local Director & Shareholder",
    },
    { value: "", label: "Nothing" },
  ];

  const company_nameList = GetCompany?.data?.map((el) => {
    return {
      value: el.id,
      label: el.name,
    };
  });

  const system_roleList = [
    { value: "preparer", label: "Preparer" },
    { value: "approver", label: "Approver" },
  ];

  const companyRoleValue = element?.company_role ? element?.company_role : "";

  return (
    <div className={`card ${className} !px-6 !py-5`}>
      <div className="flex justify-between mb-5">
        <p className="uppercase font-semibold text-sm">Account Details</p>
        <button onClick={() => removeAccount(index, element?.id)}>
          <Trash />
        </button>
      </div>
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            company_name:
              company_nameList?.find(
                (option) => option.value === element?.company_id
              ) || null,
            company_id: element?.company_id ?? "",
            company_role:
              company_roleList?.find(
                (option) => option.value === companyRoleValue
              ) ?? "",
            system_role:
              system_roleList?.find(
                (option) => option.value === element?.system_role
              ) || null,
          }}
          validationSchema={Yup.object().shape({
            company_name: Yup.object().required("Please Select Company Name"),
            company_role: Yup.object().required("Please Select Company Role"),
            system_role: Yup.object().required("Please Select System Role"),
          })}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-6 mb-4">
                {/* Company Name */}
                <div>
                  <div
                    className={`${
                      errors.company_name && touched.company_name
                        ? ` input-error `
                        : ""
                    } relative bg-white transition duration-300 rounded plus-number-inputs`}
                  >
                    <ReactSelect
                      className={"text-black p-0"}
                      id="company_name"
                      placeholder="Select Company Name"
                      options={company_nameList}
                      isLoading={loading}
                      // value={values.company_name}
                      getOptionValue={(company_nameList) =>
                        company_nameList.value
                      }
                      getOptionLabel={(company_nameList) =>
                        company_nameList.label
                      }
                      value={
                        company_nameList?.length > 0 &&
                        company_nameList.find(
                          (op) => op?.value === values?.company_id
                        )
                      }
                      onChange={(e) => {
                        setFieldValue("company_name", e);
                        setFieldValue("company_id", e?.id);
                        updateAccountDetailsList(
                          index,
                          e.value,
                          element?.company_role,
                          element?.system_role
                        );
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.company_name && touched.company_name
                              ? "red"
                              : "#0000003B"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      }}
                    />
                    <label
                      htmlFor="company_name"
                      className="absolute text-light_grey pointer-events-none text-base bg-linear duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 left-1"
                    >
                      Company Name
                    </label>
                  </div>
                  {errors.company_name && touched.company_name ? (
                    <InputError errorTitle={errors.company_name} />
                  ) : null}
                </div>
                {/* Company Role */}
                <div>
                  <div
                    className={`${
                      errors.company_role && touched.company_role
                        ? ` input-error `
                        : ""
                    } relative bg-white transition duration-300 rounded plus-number-inputs`}
                  >
                    <ReactSelect
                      className={"text-black p-0"}
                      id="company_role"
                      placeholder="Select Company Role"
                      options={company_roleList}
                      isLoading={false}
                      getOptionValue={(company_roleList) =>
                        company_roleList.value
                      }
                      value={values.company_role}
                      onChange={(e) => {
                        setFieldValue("company_role", e);
                        updateAccountDetailsList(
                          index,
                          element?.company_id,
                          e.value,
                          element?.system_role
                        );
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.company_role && touched.company_role
                              ? "red"
                              : "#0000003B"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      }}
                    />
                    <label
                      htmlFor="company_role"
                      className="absolute text-light_grey pointer-events-none text-base bg-linear duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 left-1"
                    >
                      Company Role
                    </label>
                  </div>
                  {errors.company_role && touched.company_role ? (
                    <InputError errorTitle={errors.company_role} />
                  ) : null}
                </div>
                {/* System Role */}
                <div>
                  <div
                    className={`${
                      errors.system_role && touched.system_role
                        ? ` input-error `
                        : ""
                    } relative bg-white transition duration-300 rounded plus-number-inputs`}
                  >
                    <ReactSelect
                      className={"text-black p-0"}
                      id="system_role"
                      placeholder="Select System Role"
                      options={system_roleList}
                      isLoading={false}
                      getOptionValue={(system_roleList) =>
                        system_roleList.value
                      }
                      value={values.system_role}
                      onChange={(e) => {
                        setFieldValue("system_role", e);
                        updateAccountDetailsList(
                          index,
                          element?.company_id,
                          element?.company_role,
                          e.value
                        );
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.system_role && touched.system_role
                              ? "red"
                              : "#0000003B"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      }}
                    />
                    <label
                      htmlFor="system_role"
                      className="absolute text-light_grey pointer-events-none text-base bg-linear duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 left-1"
                    >
                      System Role
                    </label>
                  </div>
                  {errors.system_role && touched.system_role ? (
                    <InputError errorTitle={errors.system_role} />
                  ) : null}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AccountDetails;
