import React from "react";
import ReactSelect from "react-select";
import { InputError } from "../../commons/MicroComponents";
import { InputWithLabel } from "../../components/micro";

const RequestDetailsField = ({
  element,
  UpdateRequestDetails,
  index,
  setFieldValue,
  error_service_request,
  error_pricing,
}) => {
  const service_request_options = [
    {
      label: "Appointment of New Local Director",
      value: "appointment_of_new_local_director",
    },
    {
      label: "Appointment of New Foreign Director",
      value: "appointment_of_new_foreign_director",
    },
    {
      label: "Open Bank Account",
      value: "open_bank_account",
    },
    {
      label: "Close Bank Account",
      value: "close_bank_account",
    },
    {
      label: "Change of Company Name",
      value: "change_of_company_name",
    },
    {
      label: "Change of Business Activities",
      value: "change_of_business_activities",
    },
    {
      label: "Change of Company Registered Address",
      value: "change_of_company_registered_address",
    },
    {
      label: "Change of Director's Particulars",
      value: "change_of_director's_particulars",
    },
    {
      label: "Change of Shareholder's Particular",
      value: "change_of_shareholder's_particular",
    },
    {
      label: "Change of Financial Year End",
      value: "change_of_financial_year_end",
    },
    {
      label: "Allotment of Shares",
      value: "allotment_of_shares",
    },
  ];
  return (
    <>
      <div className="grid grid-cols-2 gap-5 w-full">
        {/* Service Request */}
        <div>
          <div
            className={`relative bg-white transition duration-300 rounded plus-number-inputs`}
          >
            <ReactSelect
              className={"text-black p-0"}
              id="service_request"
              name="service_request"
              placeholder="Select Service Request"
              options={service_request_options}
              getOptionValue={(service_request_options) => service_request_options.value}
              value={element.service_request}
              onChange={(e) => {
                UpdateRequestDetails(e, element.pricing, index);
                setFieldValue("service_request", e.value);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid ${
                    error_service_request ? "red" : "#0000003B"
                  }`,
                  borderRadius: "0.25rem",
                  padding: "6px 4px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #0000003B",
                  },
                }),
              }}
            />
            <label
              htmlFor="service_request"
              className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
            >
              Service Request
            </label>
          </div>
          {error_service_request && (
            <InputError errorTitle={error_service_request} />
          )}
        </div>
        {/* Pricing ($) */}
        <InputWithLabel
          className={error_pricing ? ` input-error ` : ` border `}
          idFromName="pricing"
          label="Pricing ($)"
          type="text"
          onChange={(e) => {
            UpdateRequestDetails(
              element.service_request,
              e.target.value,
              index
            );
            setFieldValue("pricing", e.target.value);
          }}
          value={element.pricing}
          errors={error_pricing}
          errorsText={error_pricing}
        />
      </div>
    </>
  );
};

export default RequestDetailsField;
