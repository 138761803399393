import React, { useState } from "react";
import Breadcrumb from "../../commons/Breadcrumb";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputError } from "../../commons/MicroComponents";
import ReactSelect from "react-select";
import { AddButton, InputWithLabel } from "../../components/micro";
import { Trash } from "../../assets/svg/AllSvg";
import SubscriptionField from "./SubscriptionField";

const CreateSubscription = () => {
  const [subscription_list, setSubscription_list] = useState([
    { subscription: "", pricing: "" },
  ]);
  const companyList = [
    { value: "system_one", label: "system One" },
    { value: "system_two", label: "system Two" },
  ];
  // duration List
  const durationList = [
    { value: "role", label: "Role" },
    { value: "roleOne", label: "Role One" },
    { value: "roleTwo", label: "Role Two" },
  ];
  // Update Request Details
  const UpdateSubscription = (subscription, pricing, index) => {
    const updated_subscription = subscription_list?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          subscription: subscription,
          pricing: pricing,
        };
      } else {
        return item;
      }
    });
    setSubscription_list(updated_subscription);
  };

  // Remove Request Details
  const removeSubscription = (index) => {
    setSubscription_list([
      ...subscription_list.slice(0, index),
      ...subscription_list.slice(index + 1),
    ]);
  };
  return (
    <div className="space-y-5">
      <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
        Create Sales
      </h6>
      <Breadcrumb
        breadCrumbParent={"Home"}
        breadCrumbActive={"Create Sales"}
        breadCrumbTitleKey={"/"}
        breadCrumbParent2={"Sales"}
        classes=""
      />
      <div className="w-full bg-white border rounded py-5 px-6">
        <Formik
          enableReinitialize={true}
          initialValues={{
            company: "",
            email: "",
            remarks: "",
            duration: "",
          }}
          validationSchema={Yup.object().shape({
            company: Yup.object().required("Please Select Primary Activity"),
            email: Yup.string().required("Please Enter Email"),
            remarks: Yup.string().required("Please Enter Remarks"),
            duration: Yup.object().required("Please Select Duration"),
            subscription: Yup.string().required("Please Enter Subscription"),
            subscription_pricing: Yup.string().required("Please Enter Pricing"),
          })}
          onSubmit={(values) => {
            console.log(values, "values");
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} className="space-y-5">
              <h1 className="font-ebGaramond font-semibold text-4xl">
                Subscription
              </h1>
              <div className="pb-5 border-b border-secondary_grey">
                <p className="text-xl font-ebGaramond mb-5">
                  1. Company Details
                </p>
                <div className="grid grid-cols-2 gap-5">
                  {/* Company */}
                  <div>
                    <div
                      className={`${
                        errors.company && touched.company ? ` input-error ` : ""
                      } relative bg-white transition duration-300 rounded plus-number-inputs`}
                    >
                      <ReactSelect
                        className={"text-black p-0"}
                        id="company"
                        placeholder="Select Company"
                        options={companyList}
                        isLoading={false}
                        getOptionValue={(companyList) => companyList.value}
                        value={values.company}
                        onChange={(e) => {
                          setFieldValue("company", e);
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: `1px solid ${
                              errors.company && touched.company
                                ? "red"
                                : "#0000003B"
                            }`,
                            borderRadius: "0.25rem",
                            padding: "6px 4px",
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #0000003B",
                            },
                          }),
                        }}
                      />
                      <label
                        htmlFor="company"
                        className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Company
                      </label>
                    </div>
                    {errors.company && touched.company ? (
                      <InputError errorTitle={errors.company} />
                    ) : null}
                  </div>
                  {/* Email */}
                  <InputWithLabel
                    className={
                      errors.email && touched.email
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="email"
                    label="Email"
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    errors={errors.email && touched.email}
                    errorsText={errors.email}
                  />
                </div>
              </div>
              <div className="pb-5 border-b border-secondary_grey">
                <p className="text-xl font-ebGaramond mb-5">
                  2. Subscription Details
                </p>
                <div className="space-y-5">
                  {subscription_list?.map((element, index) => {
                    return (
                      <div className="flex">
                        <SubscriptionField
                          element={element}
                          UpdateSubscription={UpdateSubscription}
                          index={index}
                          setFieldValue={setFieldValue}
                          error_subscription={errors.subscription}
                          error_subscription_pricing={
                            errors.subscription_pricing
                          }
                        />
                        {!(subscription_list.length - 1 === index) && (
                          <button
                            className="p-4"
                            onClick={() => {
                              removeSubscription(index);
                            }}
                          >
                            <Trash />
                          </button>
                        )}
                      </div>
                    );
                  })}
                  {/* Duration */}
                  <div className="grid grid-cols-2 gap-5">
                    <div>
                      <div
                        className={`${
                          errors.duration && touched.duration
                            ? ` input-error `
                            : ""
                        } relative bg-white transition duration-300 rounded plus-number-inputs`}
                      >
                        <ReactSelect
                          className={"text-black p-0"}
                          id="duration"
                          placeholder="Select Duration"
                          options={durationList}
                          isLoading={false}
                          getOptionValue={(durationList) => durationList.value}
                          value={values.duration}
                          onChange={(e) => {
                            setFieldValue("duration", e);
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              border: `1px solid ${
                                errors.duration && touched.duration
                                  ? "red"
                                  : "#0000003B"
                              }`,
                              borderRadius: "0.25rem",
                              padding: "6px 4px",
                              boxShadow: "none",
                              "&:hover": {
                                border: "1px solid #0000003B",
                              },
                            }),
                          }}
                        />
                        <label
                          htmlFor="duration"
                          className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        >
                          Duration
                        </label>
                      </div>
                      {errors.duration && touched.duration ? (
                        <InputError errorTitle={errors.duration} />
                      ) : null}
                    </div>
                  </div>
                  <AddButton
                    className={"!justify-end py-0"}
                    value="Subscription"
                    onClick={() => {
                      setSubscription_list([
                        ...subscription_list,
                        {
                          subscription: "",
                          pricing: "",
                        },
                      ]);
                    }}
                  />
                </div>
              </div>
              {/* Remarks */}
              <InputWithLabel
                className={
                  errors.remarks && touched.remarks
                    ? ` input-error `
                    : ` border `
                }
                idFromName="remarks"
                label="Remarks"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.remarks}
                errors={errors.remarks && touched.remarks}
                errorsText={errors.remarks}
              />
              <div className="col-span-2 flex justify-end gap-4 pt-3">
                <button type="submit" className="btn uppercase">
                  Cancel
                </button>
                <button type="submit" className="btn-theme uppercase">
                  Create
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateSubscription;
