import React, { useEffect } from "react";
import CustomTable from "../../commons/CustomTable";
import { CancelCircle, CheckCircle, Close } from "../../assets/svg/AllSvg";
import { getPermissionsList } from "../../redux/accounts/slice";
import { useDispatch, useSelector } from "react-redux";
import RippleLoader from "../../commons/RippleLoader";

const ViewPermissionList = ({
  setViewPermissionListPopup,
  viewPermissionData,
  setAddEditRolePopup,
}) => {
  const dispatch = useDispatch();
  const permissionList = {
    data: [
      {
        taskName: "Module 1",
      },
    ],
  };

  const { permissionsList, permissionListLoading } = useSelector((store) => ({
    permissionsList: store?.accountsData?.getPermissionsListStatus?.data,
    permissionListLoading: store?.accountsData?.loading,
  }));

  useEffect(() => {
    dispatch(getPermissionsList(viewPermissionData.id));
  }, [dispatch, viewPermissionData]);

  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b font-semibold text-left bg-secondary_light_shades whitespace-nowrap rounded-tl-md"
        >
          Head
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-secondary_light_shades"
        >
          Create
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-secondary_light_shades"
        >
          Update
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-secondary_light_shades"
        >
          Read
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-secondary_light_shades"
        >
          Delete
        </th>
      </>
    );
  };

  const DataRows = () => {
    return (
      <>
        {(permissionsList?.data || [])?.map((element) => (
          <>
            {!element?.sub_menu?.length ? (
              <tr className={"border-b text-sm"} key={element?.id}>
                <td className="px-5 py-3">{element?.title}</td>
                {element?.child_permission?.map((child_permission) => {
                  return (
                    <td className="px-5 py-3">
                      {child_permission?.assigned ? (
                        <CheckCircle />
                      ) : (
                        <CancelCircle />
                      )}
                    </td>
                  );
                })}
              </tr>
            ) : (
              <>
                <tr className="text-sm">
                  <td className="px-5 py-3">{element?.title}:</td>
                </tr>
                {element?.sub_menu?.map((sub, index) => {
                  return (
                    <tr
                      className={`${
                        element?.sub_menu?.length - 1 === index && "border-b"
                      } text-sm`}
                    >
                      <td className="px-5 py-3">{sub?.title}</td>
                      {sub?.child_permission?.map((child_permission) => {
                        return (
                          <td className="px-5 py-3">
                            {child_permission?.assigned ? (
                              <CheckCircle />
                            ) : (
                              <CancelCircle />
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            )}
          </>
        ))}
      </>
    );
  };
  return (
    <div
      className="model"
      onClick={() => {
        setViewPermissionListPopup(false);
      }}
    >
      <div
        className="bg-white flex flex-col items-end justify-center drop-shadow-lg rounded-lg w-full max-w-xl min-h-36 py-5 px-6 mx-auto relative"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <p className="text-xl font-ebGaramond font-semibold w-full border-b pb-3 mb-4">
          {viewPermissionData?.title}
        </p>
        <Close
          className="cursor-pointer absolute top-5 right-5"
          onClick={() => {
            setViewPermissionListPopup(false);
          }}
        />
        {permissionListLoading ? (
          <div className="w-full h-96 flex justify-center items-center">
            <RippleLoader />
          </div>
        ) : (
          <div className="w-full max-h-96 overflow-auto mb-3">
            <CustomTable
              columnHeaders={<ColumnHeaders />}
              dataRows={<DataRows />}
              data={permissionList}
              loading={permissionListLoading}
            />
          </div>
        )}
        <button
          className="btn-theme"
          onClick={() => {
            setViewPermissionListPopup(false);
            setAddEditRolePopup(true);
          }}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

export default ViewPermissionList;
