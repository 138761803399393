import React from "react";
import { Logo } from "../../assets/image";

const PdfComponent = () => {
  return (
    <div>
      <div
        id="pdfContent"
        style={{
          padding: "20px",
          background: "#fff",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={Logo}
            alt="Company Logo"
            style={{ width: "80px", height: "80px", objectFit: "cover" }}
          />
          <div style={{ maxWidth: "15rem" }}>
            <h2 style={{ margin: 0 }}>EiBiz</h2>
            <p style={{ margin: 0 }}>
              <strong>Address:</strong> 123 Seng Kang #01-03, S123456
            </p>
            <p style={{ margin: 0 }}>
              <strong>Contact No:</strong> 9090 9090
            </p>
          </div>
        </div>

        <h1
          style={{
            fontSize: "2.25rem",
            lineHeight: "2.5rem",
            marginBottom: "20px",
          }}
        >
          Receipt Summary
        </h1>

        <div>
          <p>
            <strong>ID:</strong> #0001
          </p>
          <p>
            <strong>Customer Name:</strong> Customer Name 1
          </p>
          <p>
            <strong>Customer Name:</strong> Customer Pte Ltd
          </p>
          <p>
            <strong>Date:</strong> 17/07/2023 | 14:00 PM
          </p>
        </div>

        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  padding: "0.75rem 1.25rem",
                  fontWeight: 600,
                  backgroundColor: "0656990A",
                }}
              >
                No.
              </th>
              <th
                style={{
                  padding: "0.75rem 1.25rem",
                  fontWeight: 600,
                  backgroundColor: "0656990A",
                }}
              >
                Request
              </th>
              <th
                style={{
                  padding: "0.75rem 1.25rem",
                  fontWeight: 600,
                  backgroundColor: "0656990A",
                }}
              >
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                01
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                Change of Registered Company Address
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "right",
                }}
              >
                $300.00
              </td>
            </tr>
          </tbody>
        </table>

        <div style={{ marginTop: "20px", textAlign: "right" }}>
          <p>
            <strong>Subtotal:</strong> $300.00
          </p>
          <p>
            <strong>Total:</strong> $300.00
          </p>
        </div>
      </div>
    </div>
  );
};

export default PdfComponent;
