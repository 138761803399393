import React, { useState } from "react";
import { Logo, authBgImage } from "../../assets/image";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputError } from "../../commons/MicroComponents";
import { forgetPassword } from "../../redux/auth/slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InputWithLabel } from "../../components/micro";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <div className={`h-screen overflow-hidden relative`}>
        <div
          className={`flex items-center justify-center h-full  bg-no-repeat bg-cover bg-center`}
          style={{
            backgroundImage: `url(${authBgImage})`,
          }}
        >
          <div className="absolute top-10 left-10 w-20 h-20">
            <img src={Logo} alt="logo" className="w-full h-full object-cover" />
          </div>
          <section className="w-full">
            <div className="max-w-xl w-full mx-auto">
              <div className="rounded-lg drop-shadow-lg bg-white/80 px-6 py-8 mx-4">
                <div className={"mb-8"}>
                  <h1 className="sm:text-2xl text-lg font-ebGaramond font-semibold mb-1">
                    Reset Password
                  </h1>
                  <p className="text-sm opacity-65 mb-1">
                    We will send you a link to reset ?
                  </p>
                  <p className="text-sm opacity-65">your password via email</p>
                </div>
                <Formik
                  initialValues={{
                    email: "",
                    role: "admin",
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string().required("Please Enter Email"),
                  })}
                  onSubmit={(values) => {
                    setLoading(true);
                    dispatch(forgetPassword(values)).then((res) => {
                      setLoading(false);
                      if (res.type === "forgetPassword/fulfilled") {
                        navigate("login");
                      }
                    });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="space-y-5">
                        {/* Email */}
                        <InputWithLabel
                          className={
                            errors.email && touched.email
                              ? ` input-error `
                              : ` border `
                          }
                          idFromName="email"
                          label="Email"
                          type="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          errors={errors.email && touched.email}
                          errorsText={errors.email}
                        />
                        <button
                          type="submit"
                          className="w-full block text-white bg-theme text-center text-sm border-2 border-transparent hover:bg-transparent hover:border-2 hover:border-theme hover:text-theme rounded transition duration-500 py-2 lg:px-7 px-4"
                        >
                          {loading ? "loading...." : "Send me the reset link"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
