import React, { useContext, useEffect, useState } from "react";
import {
  ArrowForward,
  BackArrow,
  Info,
  RoundInfo,
} from "../../../../assets/svg/AllSvg";
import CompanyContext from "../CompanyContext";
import { useDispatch, useSelector } from "react-redux";
import {
  employmentPass,
  employmentSupport,
} from "../../../../redux/companies/slice";
import ButtonLoader from "../../../../commons/ButtonLoader";
import { Logo } from "../../../../assets/image";

const Accounting = ({ companyByIdData, companyId }) => {
  const [epCount, setEpCount] = useState(1);
  const [locCount, setLocCount] = useState(1);
  const [isSupportNeeded, setIsSupportNeeded] = useState(true);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { prev, next } = useContext(CompanyContext);

  useEffect(() => {
    dispatch(employmentPass());
    dispatch(employmentSupport());
  }, [dispatch]);

  const { EmploymentPass } = useSelector((store) => ({
    EmploymentPass: store?.companiesData?.employmentPassStatus?.data?.data,
  }));

  const handleEpIncrement = () => {
    setEpCount((prevCount) => (prevCount < 9 ? prevCount + 1 : prevCount));
  };

  const handleEpDecrement = () => {
    setEpCount((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount));
  };

  const handleLocIncrement = () => {
    setLocCount((prevCount) => (prevCount < 9 ? prevCount + 1 : prevCount));
  };

  const handleLocDecrement = () => {
    setLocCount((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount));
  };

  useEffect(() => {
    setIsSupportNeeded(companyByIdData?.employment_support);
    setEpCount(companyByIdData?.no_of_eps);
    setLocCount(companyByIdData?.no_of_locs);
  }, [companyByIdData]);

  const handleSaveAndProceed = () => {
    setLoading(true);
    if (isSupportNeeded) {
      dispatch(
        employmentSupport({
          id: companyId,
          data: {
            employment_support: isSupportNeeded,
            no_of_eps: epCount,
            no_of_locs: locCount,
          },
        })
      )
        .then(() => {
          setLoading(false);
          next();
        })
        .catch((error) => {
          console.error("Error dispatching employmentSupport:", error);
        });
    } else {
      dispatch(
        employmentSupport({
          id: companyId,
          data: {
            employment_support: isSupportNeeded,
            no_of_eps: 0,
            no_of_locs: 0,
          },
        })
      )
        .then(() => {
          console.log("API call succeeded, proceeding to next step");
          next();
        })
        .catch((error) => {
          console.error("Error dispatching employmentSupport:", error);
        });
    }
  };

  return (
    <div className="card border p-6 shadow-lg bg-white h-auto">
      <div className="pb-2">
        <p className="font-poppins text-sm">One Last Thing!</p>
        <p className="font-ebGaramond font-normal text-xl">
          Do you or anyone else in your company need employment <br /> visa
          support from us?
        </p>
      </div>
      <p className="border" />
      <div className="w-full flex">
        <button
          className={`w-1/2 border px-2 my-4 mr-2 rounded-xl ${
            isSupportNeeded ? "bg-lightPrimaryShades" : ""
          }`}
          onClick={() => setIsSupportNeeded(true)}
        >
          <div className="w-full flex py-2">
            <div>
              <img className="h-14 w-14" src={Logo} alt="logo" />
            </div>
            <div className="w-60 py-1 px-4">
              <p className="font-poppins text-base font-normal w-60 -ml-2 text-start">
                Yes! I will need EiBIZ support on employment
              </p>
            </div>
          </div>
        </button>
        <button
          className={`w-1/2 border px-4 my-4 rounded-xl ${
            !isSupportNeeded ? "bg-lightPrimaryShades" : ""
          }`}
          onClick={() => setIsSupportNeeded(false)}
        >
          <div className="w-full flex py-2">
            <div className="w-60 py-1 px-3">
              <p className="font-poppins text-base font-normal w-60 text-start">
                No! <br />
                Not at the moment
              </p>
            </div>
          </div>
        </button>
      </div>

      {isSupportNeeded === true && (
        <>
          <div className="w-full flex">
            <div>
              <div className="mt-2">
                <RoundInfo size="24" className="fill-lightInfo text-white" />
              </div>
            </div>
            <div className="ml-2">
              <p className="font-poppins font-semibold text-xs pb-0 mt-2">
                Employment Pass (EP)
              </p>
              <p className="font-poppins font-normal text-xs pb-0 mt-1">
                EP allows an employer to hire a foreigner in Singapore. It is
                the most common type of work visa.
              </p>
            </div>
            <div className="ml-2">
              <p className="font-poppins font-semibold text-xs pb-0 mt-2">
                Dependent Pass (DP)
              </p>
              <p className="font-poppins font-normal text-xs pb-0 mt-1">
                DP allows the spouse and children of an EP holder to stay in
                Singapore.
              </p>
            </div>
          </div>

          <p className="font-poppins text-sm font-normal text-start my-3">
            What kind of employment visa support will you need?
          </p>

          <div className="grid grid-cols-2 gap-5">
            <div className="my-4 flex">
              <div>
                <label
                  htmlFor="quantity-input"
                  className="block mb-1 text-sm font-normal text-gray-900"
                >
                  Number of EPs
                </label>
                <div>
                  <div className="flex items-center max-w-[8rem]">
                    <button
                      type="button"
                      id="decrement-button"
                      onClick={handleEpDecrement} // Change this line
                      className="bg-lightPrimary2 hover:bg-bgPrimary rounded-l-lg px-3 h-11"
                    >
                      <svg
                        className="w-3 h-3 text-theme"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 2"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1 1h16"
                        />
                      </svg>
                    </button>
                    <input
                      type="text"
                      id="quantity-input"
                      aria-describedby="helper-text-explanation"
                      className="bg-lightPrimary2 h-11 text-center text-theme text-sm block w-full "
                      value={epCount}
                      readOnly
                    />
                    <button
                      type="button"
                      id="increment-button"
                      onClick={handleEpIncrement} // Change this line
                      className="bg-lightPrimary2 hover:bg-bgPrimary rounded-r-lg px-3 h-11"
                    >
                      <svg
                        className="w-3 h-3 text-theme"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 18"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 1v16M1 9h16"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-end items-end ml-6">
                <p
                  id="helper-text-explanation"
                  className="mt-2 text-sm text-gray-500"
                >
                  / person
                </p>
                <p
                  id="helper-text-explanation"
                  className="text-base text-black"
                >
                  {EmploymentPass && (
                    <p className="font-poppins text-base font-light">
                      ${EmploymentPass?.employment_pass}
                    </p>
                  )}
                </p>
              </div>
            </div>
            <div className="my-4 flex">
              <div>
                <label
                  htmlFor="quantity-input"
                  className="block mb-1 text-sm font-normal text-gray-900"
                >
                  Number of LOC’s
                </label>
                <div>
                  <div className="flex items-center max-w-[8rem]">
                    <button
                      type="button"
                      id="decrement-button"
                      onClick={handleLocDecrement} // Change this line
                      className="bg-lightPrimary2 hover:bg-bgPrimary rounded-l-lg px-3 h-11"
                    >
                      <svg
                        className="w-3 h-3 text-theme"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 2"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1 1h16"
                        />
                      </svg>
                    </button>
                    <input
                      type="text"
                      id="quantity-input"
                      aria-describedby="helper-text-explanation"
                      className="bg-lightPrimary2 h-11 text-center text-theme text-sm block w-full "
                      value={locCount}
                      readOnly
                    />
                    <button
                      type="button"
                      id="increment-button"
                      onClick={handleLocIncrement} // Change this line
                      className="bg-lightPrimary2 hover:bg-bgPrimary rounded-r-lg px-3 h-11"
                    >
                      <svg
                        className="w-3 h-3 text-theme"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 18"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 1v16M1 9h16"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-end items-end ml-6">
                <p
                  id="helper-text-explanation"
                  className="mt-2 text-sm text-gray-500"
                >
                  / person
                </p>
                <p
                  id="helper-text-explanation"
                  className="text-base text-black"
                >
                  {EmploymentPass && (
                    <p className="font-poppins text-base font-light">
                      ${EmploymentPass?.dependant_pass_loc}
                    </p>
                  )}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      <p className="border" />

      {/* buttons */}
      <div className="flex mt-4 justify-end gap-3">
        <button
          type="button"
          className="btn-outline flex items-center space-x-3 uppercase"
          onClick={() => prev()}
        >
          <BackArrow className="fill-theme" fillOpacity={1} />
          <span>Back</span>
        </button>
        <button
          onClick={handleSaveAndProceed}
          disabled={loading}
          className="btn-theme flex items-center space-x-3 uppercase"
        >
          {loading ? <ButtonLoader /> : <span>Save & Proceed On</span>}{" "}
          <ArrowForward />
        </button>
      </div>
    </div>
  );
};

export default Accounting;
