import React, { useContext, useState, useEffect, useRef } from "react";
import { HambergerMenu, Logout, ProfileCircle } from "iconsax-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { userDefault } from "../assets/image";
import layoutContext from "./layoutContext";
import { AdminContext } from ".";
import { Avatar, ChatFilled, Icon, Notifications } from "../assets/svg/AllSvg";

const Header = () => {
  const { isAsideOpen, setIsAsideOpen } = useContext(layoutContext);
  const adminData = useContext(AdminContext);

  const navigate = useNavigate();

  return (
    <>
      <header className="sticky top-0 left-0 w-full z-[9]">
        <div className="shadow-md bg-secondary h-16 w-full flex justify-between items-center px-4 ">
          <div className="flex gap-2 cursor-pointer">
            <span onClick={() => setIsAsideOpen(!isAsideOpen)}>
              <div className="text-white flex items-center">
                <HambergerMenu size="22" className="mr-3" />
                <p className="text-sm">EI BIZ</p>
              </div>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <div
              className="relative cursor-pointer"
              onClick={() => {
                navigate("/notifications");
              }}
            >
              <Notifications />
              <span className="absolute top-0 right-0 translate-x-1 w-1.5 h-1.5 bg-secondary_light rounded-full"></span>
            </div>
            <div
              className="relative cursor-pointer"
              onClick={() => {
                navigate("/chat");
              }}
            >
              <ChatFilled />
              <span className="absolute top-0 right-0 -translate-y-1 translate-x-1 text-xs text-white">
                1
              </span>
            </div>
            <div className="relative">
              <Icon />
              <span className="absolute top-0 right-0 -translate-y-1 translate-x-1 text-xs text-white">
                1
              </span>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => navigate("/profile")}
            >
              <Avatar />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
