import React, { useState } from "react";
import Breadcrumb from "../../../commons/Breadcrumb";
import { InputWithLabel, TextareaWithLabel } from "../../../components/micro";
import Tab from "../../../commons/Tab";

const Accounting = () => {
  const [activeTab, setActiveTab] = useState("Annual Filing & Book Keeping");

  const tabs = [
    { label: "1 Year", content: "" },
    { label: "3 Year", content: "" },
    { label: "5 Year", content: "" },
  ];
  const subTabs = [
    { label: "Annual Filing & Book Keeping", content: "" },
    { label: "Accounting & Payroll", content: "" },
    { label: "Accounting Premium", content: "" },
  ];
  return (
    <>
      <div className="mb-5">
        <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
          Service Setting
        </h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbParent2={"Service Setting"}
          breadCrumbActive={"Accounting"}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>
      <div className="w-full bg-white border rounded py-5 px-6">
        <h1 className="font-ebGaramond font-semibold text-4xl mb-5">
          Accounting
        </h1>
        <div className="grid grid-cols-6 items-center mb-5">
          <p className="text-light_grey">Gov Annual Filing Fees</p>
          <div className="col-span-5 w-full">
            <InputWithLabel
              label={"Pricing"}
              type={"text"}
              idFromName={"pricing"}
              className={"border"}
            />
          </div>
        </div>
        <Tab tabs={tabs} rightSide={true} btnClass="font-semibold mb-5" />
        <div className="flex border border-theme rounded overflow-hidden mb-6">
          {subTabs.map((tab, index) => (
            <button
              key={tab.label}
              className={`py-2 px-4 uppercase text-center text-theme font-semibold w-full transition duration-300 ${
                activeTab === tab.label ? "bg-theme/30" : ""
              } ${index < 2 && "border-r border-theme"}`}
              onClick={() => setActiveTab(tab.label)}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <div className="grid grid-cols-6 gap-5 items-center mb-5">
          <p className="text-light_grey">Up to $30,000</p>
          <div className="col-span-5 w-full">
            <InputWithLabel
              label={"Pricing"}
              type={"text"}
              idFromName={"pricing"}
              className={"border"}
            />
          </div>
          <p className="text-light_grey">Up to $90,000</p>
          <div className="col-span-5 w-full">
            <InputWithLabel
              label={"Pricing"}
              type={"text"}
              idFromName={"pricing"}
              className={"border"}
            />
          </div>
          <p className="text-light_grey">Up to $180,000</p>
          <div className="col-span-5 w-full">
            <InputWithLabel
              label={"Pricing"}
              type={"text"}
              idFromName={"pricing"}
              className={"border"}
            />
          </div>
        </div>
        {/* Remarks */}
        <div>
          <TextareaWithLabel
            className={"border"}
            idFromName="remarks"
            label="Remarks"
            // onChange={handleChange}
            // onBlur={handleBlur}
            // value={values.remarks}
            // errors={errors.remarks && touched.remarks}
            // errorsText={errors.remarks}
          />
        </div>
        <div className="col-span-2 flex justify-end gap-4 pt-3">
          <button type="submit" className="btn uppercase">
            Cancel
          </button>
          <button type="submit" className="btn-theme uppercase">
            {false ? "Save" : "Edit"}
          </button>
        </div>
      </div>
    </>
  );
};

export default Accounting;
