import React, { useState } from "react";
import { ChatFilled, Plus } from "../../assets/svg/AllSvg";
import ClientsList from "./ClientsList";
import ChatContainer from "./ChatContainer";
import Documents from "./Documents";
import NewChatModel from "./components/NewChatModel";
import { InputError } from "../../commons/MicroComponents";

const Chat = () => {
  const [showNewChatModel, setShowNewChatModel] = useState(false);
  const [newChatName, setNewChatName] = useState({});
  const [chatDocuments, setChatDocuments] = useState([]);

  return (
    <>
      <div className="card !rounded-2xl space-y-5">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-3">
            <ChatFilled fillClass="fill-light_grey" className="w-7 h-7" />
            <p className="text-3xl font-ebGaramond">Chat</p>
          </div>
          <button
            className="btn-theme flex items-center space-x-3 !rounded-full"
            onClick={() => setShowNewChatModel(true)}
          >
            <Plus />
            <span>New Chat</span>
          </button>
        </div>
        <div className="grid grid-cols-5">
          <ClientsList setNewChatName={setNewChatName} />
          {newChatName?.id ? (
            <>
              <ChatContainer
                newChatName={newChatName}
                setChatDocuments={setChatDocuments}
              />
              <Documents chatDocuments={chatDocuments} />
            </>
          ) : (
            <div className="col-span-4 flex justify-center items-center">
              <InputError errorTitle={"Please select Client"} />
            </div>
          )}
        </div>
      </div>
      {showNewChatModel && (
        <NewChatModel
          onClose={() => setShowNewChatModel(false)}
          setShowNewChatModel={setShowNewChatModel}
          setNewChatName={setNewChatName}
        />
      )}
    </>
  );
};

export default Chat;
