import React, { useEffect, useRef, useState } from "react";
import { Close, ImgSearchIcon, PDFDoc, Send } from "../../assets/svg/AllSvg";
import AvatarUser from "../../commons/AvatarUser";
import ReceiveMsg from "../../commons/ReceiveMsg";
import SendMsg from "../../commons/SendMsg";
import Action from "../../commons/Action";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import moment from "moment";
import { useDispatch } from "react-redux";
import { uploadFile } from "../../redux/uploadFile/slice";
import RippleLoader from "../../commons/RippleLoader";
import { InputError } from "../../commons/MicroComponents";
import FileUploadChoose from "../../commons/FileUploadChoose";

const ChatContainer = ({ element, newChatName, setChatDocuments }) => {
  const [isOpenFileUpload, setIsOpenFileUpload] = useState(false);
  const [error, setError] = useState(null);
  const [chatDetails, setChatDetails] = useState([]);
  const [documentArray, setDocumentArray] = useState([]);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [chatText, setChatText] = useState("");
  const accessAdminToken = sessionStorage.getItem("accessToken");
  const adminId = sessionStorage.getItem("adminId");
  const chatEndRef = useRef(null);
  const socketRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Start Socket
  useEffect(() => {
    socketRef.current = io("http://103.47.29.22:3000/", {
      auth: {
        token: accessAdminToken,
      },
    });

    socketRef.current.emit("getChat", {
      chatId: newChatName?.id,
    });

    socketRef.current.on("error", (err) => {
      setError(err);
    });

    socketRef.current.on("chatList", (data) => {
      setChatDetails(data?.data?.messages);
      setChatDocuments(data?.data?.documents);
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [newChatName?.id]);

  // Scroll to the bottom whenever messages change
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatDetails]);

  const getNewMessage = () => {
    socketRef.current.on("newMessage", (data) => {
      setChatDetails([...chatDetails, { ...data?.data, sender: true }]);
    });
    setChatText("");
  };

  const sendMsg = () => {
    socketRef.current.emit("sendMessage", {
      content: chatText,
      chat_id: newChatName?.id,
      sender_id: adminId,
      type: "text",
    });
    getNewMessage();
  };

  // File Upload

  // Get File URL Upload Editor
  const handleUpload = (e) => {
    setUploadError(false);
    const file = e?.target?.files[0];
    const type = file?.type;
    const formData = new FormData();
    formData.append("type", "chat");
    formData.append("file", file);
    formData.append(
      "document_type",
      type === "application/pdf" ? "doc" : "img"
    );
    setIsFileUpload(true);
    dispatch(uploadFile(formData)).then((res) => {
      setIsFileUpload(false);
      if (res.type === "uploadFile/fulfilled") {
        setDocumentArray((prevArray) => [...prevArray, res.payload.data]);
      }
    });
  };

  // Remove Upload Document
  const removeItem = (index) => {
    setDocumentArray([
      ...documentArray.slice(0, index),
      ...documentArray.slice(index + 1),
    ]);
  };

  //   Upload On Chat
  const handleUploadOnChat = () => {
    if (documentArray?.length) {
      const fillArray = documentArray?.map((e) => e.id);
      socketRef.current.emit("sendMessage", {
        chat_id: newChatName?.id,
        sender_id: adminId,
        type: "media",
        url: fillArray,
      });
      getNewMessage();
    } else {
      setUploadError(true);
      return;
    }
  };

  return (
    <>
      <div className="col-span-3 border-r flex flex-col justify-between px-5">
        <div className="flex justify-between items-center border-b pb-3">
          {newChatName?.model?.id == adminId ? (
            <AvatarUser
              className={
                "hover:bg-light_secondary_shades rounded-xl cursor-pointer px-4 py-3"
              }
              isActive={newChatName?.isActive}
              img={newChatName?.receiver?.profile_path}
              name={newChatName?.receiver?.full_name}
              company_name={newChatName?.receiver?.name}
              date={moment(newChatName?.updated_at).format("DD/MM/YYYY HH:MM")}
            />
          ) : (
            <AvatarUser
              className={
                "hover:bg-light_secondary_shades rounded-xl cursor-pointer px-4 py-3"
              }
              isActive={newChatName?.isActive}
              img={newChatName?.model?.profile_path}
              name={newChatName?.model?.full_name}
              company_name={newChatName?.model?.name}
              date={moment(newChatName?.updated_at).format("DD/MM/YYYY HH:MM")}
            />
          )}
          <Action
            optionList={
              <>
                <li
                  className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                  onClick={() =>
                    navigate("/chat", {
                      state: {
                        element: element,
                      },
                    })
                  }
                >
                  Archive
                </li>
                <li
                  className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                  onClick={() => {
                    // setCompanyData(element);
                    // setShowConfirmArchivePopup(true);
                  }}
                >
                  Block
                </li>
              </>
            }
          />
        </div>
        <div className="h-[calc(100vh-353px)] overflow-auto py-3 space-y-2">
          {chatDetails?.length && (
            <>
              {chatDetails?.map((element) => {
                return (
                  <>
                    {element?.sender ? (
                      <SendMsg element={element} />
                    ) : (
                      <ReceiveMsg element={element} />
                    )}
                  </>
                );
              })}
            </>
          )}
          <div ref={chatEndRef} />
        </div>
        <div className="py-2">
          <div
            className={`relative bg-white border-secondary_grey transition duration-300 rounded border`}
          >
            <input
              type="text"
              id={"message"}
              name={"message"}
              className="block px-2.5 pb-2.5 pr-20 pt-4 w-full text-sm error-forms text-gray-900 bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder={" "}
              onChange={(e) => setChatText(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && sendMsg()}
              value={chatText}
            />
            <div className="absolute top-1/2 right-0 -translate-y-1/2 -translate-x-3 flex items-center">
              <button
                className="relative p-2 overflow-hidden"
                onClick={() => setIsOpenFileUpload(true)}
              >
                <ImgSearchIcon />
              </button>
              <button type="submit" className="p-2" onClick={sendMsg}>
                <Send />
              </button>
            </div>
            <label
              htmlFor={"message"}
              className="absolute text-light_grey pointer-events-none text-xs bg-white duration-300 transform -translate-y-1/2 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-full peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-5 left-1"
            >
              Message
            </label>
          </div>
        </div>
      </div>
      {isOpenFileUpload && (
        <div className="model" onClick={() => setIsOpenFileUpload(false)}>
          <div
            className="bg-white drop-shadow-lg rounded-lg w-full max-w-xl min-h-40 py-8 px-6 mx-auto relative"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="space-y-5">
              <p className="text-theme">Upload File(s)</p>
              {isFileUpload ? (
                <RippleLoader />
              ) : (
                <>
                  {documentArray?.length ? (
                    <div className="flex flex-wrap gap-2">
                      {documentArray?.map((element, index) => {
                        return (
                          <>
                            {element.document_type === "doc" ? (
                              <div className="w-32 flex flex-col items-end space-y-2 group">
                                <button
                                  className="opacity-0 group-hover:opacity-100"
                                  onClick={() => removeItem(index)}
                                >
                                  <Close />
                                </button>
                                <div className="card h-24 w-full space-y-2 flex flex-col items-center justify-center border-4 border-transparent group-hover:border-light_green !py-1">
                                  <PDFDoc />
                                  <p className="max-w-full w-full text-sm truncate">
                                    {element?.name}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div className="w-20 flex flex-col items-end space-y-2 group">
                                <button
                                  className="opacity-0 group-hover:opacity-100"
                                  onClick={() => removeItem(index)}
                                >
                                  <Close />
                                </button>
                                <img
                                  src={element?.url}
                                  alt="img"
                                  className="h-24 w-full object-cover rounded-lg border-4 border-transparent group-hover:border-light_green"
                                />
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  ) : null}
                </>
              )}
              {uploadError && (
                <InputError
                  errorTitle={
                    "Please Drag and Drop or choose your file for upload"
                  }
                />
              )}
              <FileUploadChoose handleFileChange={handleUpload} />
              <div className="flex justify-end gap-4">
                <button
                  className="btn-outline uppercase"
                  onClick={() => setIsOpenFileUpload(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn-theme uppercase"
                  onClick={handleUploadOnChat}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatContainer;
