import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputError } from "../../../../commons/MicroComponents";
import {
  CheckBox,
  InputWithLabel,
  RadioButton,
  SelectBox,
  TextareaWithLabel,
} from "../../../../components/micro";
import ReactSelect from "react-select";
import CompanyContext from "../CompanyContext";
import {
  ArrowForward,
  BackArrow,
  CheckCircleOutline,
  MoneyApprovedCreditcardIcon,
} from "../../../../assets/svg/AllSvg";
import { useDispatch, useSelector } from "react-redux";
import { getCountryData } from "../../../../redux/address/slice";
import {
  businessActivities,
  companyData,
  companyExist,
  CreateCompany,
} from "../../../../redux/companies/slice";
import { InfoCircle } from "iconsax-react";
import RippleLoader from "../../../../commons/RippleLoader";
import Cryptocurrency from "../components/Cryptocurrency";
import CompanyAttractionsFunds from "../components/CompanyAttractionsFunds";
import CompanyFundResources from "../components/CompanyFundResources";
import Objectives from "../components/Objectives";
import ButtonLoader from "../../../../commons/ButtonLoader";

const CompanyInformation = ({
  companyId,
  setCompanyId,
  CompanyData,
  Loading,
  createCompanyData,
}) => {
  const [companyName, setCompanyName] = useState("");
  const [countryArray, setCountryArray] = useState([]);
  const [businessActivitiesArray, setBusinessActivitiesArray] = useState([]);
  const [attractionsSelectedArray, setAttractionsSelectedArray] = useState({});
  const [objectivesSelectedArray, setObjectivesSelectedArray] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [
    companyCryptoActivitiesSelectedArray,
    setCompanyCryptoActivitiesSelectedArray,
  ] = useState({});
  const [cryptoCurrenciesSelectedArray, setCryptoCurrenciesSelectedArray] =
    useState([]);
  const [companyTypesArray, setCompanyTypesArray] = useState([]);
  const [
    companyFundResourcesSelectedArray,
    setCompanyFundResourcesSelectedArray,
  ] = useState([]);
  const [companyExistLoad, setCompanyExistLoad] = useState(false);
  const [companyExistError, setCompanyExistError] = useState(false);
  const [companyExistMessage, setCompanyExistMessage] = useState("");
  const [cryptoCurrencyRadio, setCryptoCurrencyRadio] = useState(false);
  const [attractionsError, setAttractionsError] = useState("");
  const [companyFundResourcesError, setCompanyFundResourcesError] =
    useState("");
  const [businessManagesError, setBusinessManagesError] = useState("");
  const [objectivesError, setObjectivesError] = useState("");
  const [currenciesError, setCurrenciesError] = useState("");
  const [defaultCountries, setDefaultCountries] = useState([]);
  const { next } = useContext(CompanyContext);
  const dispatch = useDispatch();

  // Selector
  const {
    countryData,
    countryLoading,
    businessActivitiesData,
    businessActivitiesLoading,
  } = useSelector((store) => ({
    // Country Data
    countryData: store?.addressData?.getCountryDataStatus?.data,
    countryLoading: store?.addressData?.getCountryDataStatus?.loading,
    // Business Activities Data
    businessActivitiesData:
      store?.companiesData?.businessActivitiesStatus?.data,
    businessActivitiesLoading:
      store?.companiesData?.businessActivitiesStatus?.loading,
  }));

  useEffect(() => {
    // dispatch(companyData());
    dispatch(getCountryData());
    dispatch(businessActivities());
  }, []);

  useEffect(() => {
    if (countryData?.data) {
      let data = countryData?.data?.map((x) => ({ ...x, label: x.name }));
      setCountryArray(data);
    }
    if (businessActivitiesData?.data) {
      let data = businessActivitiesData?.data?.map((x) => ({
        ...x,
        label: x.full_activity,
      }));
      setBusinessActivitiesArray(data);
    }
    if (CompanyData?.company_types) {
      let data = CompanyData?.company_types?.map((x) => ({
        ...x,
        label: x.title,
      }));
      setCompanyTypesArray(data);
    }
  }, [countryData, businessActivitiesData, CompanyData]);

  // set create Company Data
  useEffect(() => {
    if (createCompanyData?.id) {
      setCompanyName(createCompanyData?.name);
      setCryptoCurrencyRadio(createCompanyData?.crypto_currency);
      const countriesDefaultArray = createCompanyData?.countries?.map((e) => ({
        label: e?.name,
        ...e,
      }));
      setDefaultCountries(countriesDefaultArray);
    }
  }, [createCompanyData, CompanyData]);

  // Check Company Exist
  const handleCheckCompanyExist = (setFieldValue) => {
    setCompanyExistLoad(true);
    dispatch(companyExist({ company_name: companyName, id: companyId })).then(
      (res) => {
        setCompanyExistLoad(false);
        if (res.type === "companyExist/fulfilled") {
          if (res?.payload?.data?.exist) {
            // Company Not Exist
            setCompanyExistError(true);
            setCompanyExistMessage(res.payload.message);
            setCompanyName("");
          } else {
            // Company Exist
            setFieldValue("isVerify", true);
            setCompanyExistError(false);
            setCompanyExistMessage(res.payload.message);
          }
        }
      }
    );
  };

  // Get Initial Values
  const getInitialValues = () => {
    let initial = {
      primary_activity: "",
      primary_activity_id: createCompanyData?.primary_activity?.id ?? "",
      describe_business_activity:
        createCompanyData?.primary_business_activity ?? "",
      company_type: "",
      company_type_id: createCompanyData?.company_type_id ?? "",
      secondary_activity: "",
      secondary_activity_id: createCompanyData?.secondary_activity?.id ?? "",
      cryptocurrency: createCompanyData?.crypto_currency ? "yes" : "no",
      countries: defaultCountries ?? "",
      company_name: companyName,
      interested_d_o: createCompanyData?.interested_d_o === 1 ? true : false,
      approved_licenses: "",
      platforms: createCompanyData?.platform_id ?? "",
      isVerify: createCompanyData?.name ? true : false,
      license_mas: createCompanyData?.license_mas ? "yes" : "no",
      related_crypto_blockchain: createCompanyData?.related_crypto_blockchain
        ? "yes"
        : "no",
      about_us_other: createCompanyData?.about_us_other ?? "",
    };
    return initial;
  };

  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    validationSchema: Yup.object().shape({
      primary_activity: Yup.object().required("Please Select Primary Activity"),
      company_type: Yup.object().required("Please Select Company Type"),
      describe_business_activity: Yup.string().required(
        "Please Describe your Business Activity"
      ),
      secondary_activity: Yup.object().required(
        "Please Select Secondary Activity"
      ),
      cryptocurrency: Yup.string().required(
        "Please Select your business involved in cryptocurrency"
      ),
      isVerify: Yup.boolean()
        .oneOf([true], "Please Check Availability Company Name")
        .required("Please Check Availability Company Name"),
      license_mas: cryptoCurrencyRadio
        ? Yup.string().required("Please Select")
        : Yup.string(),
      related_crypto_blockchain: cryptoCurrencyRadio
        ? Yup.string().required("Please Select")
        : Yup.string(),
      countries: Yup.array().min(1).required("Please Select Countries"),
      platforms: Yup.string().required("Please Select Platforms"),
    }),
    onSubmit: (values) => {
      if (!attractionsSelectedArray?.attractionsSelectedArray?.length) {
        setAttractionsError("Please Select");
        return;
      }
      if (
        !companyFundResourcesSelectedArray?.companyFundResourcesSelectedArray
          ?.length
      ) {
        setCompanyFundResourcesError("Please Select");
        return;
      }
      if (!objectivesSelectedArray?.objectivesSelectedArray.length) {
        setObjectivesError("Please Select");
        return;
      }
      if (cryptoCurrencyRadio && !cryptoCurrenciesSelectedArray.length) {
        setCurrenciesError("Please Select");
        return;
      }
      setIsLoading(true);
      const payload = {
        id: createCompanyData?.id,
        name: values.company_name,
        type: "admin",
        primary_activity: values.primary_activity.id,
        secondary_activity: values.secondary_activity.id,
        business_activity: values.describe_business_activity,
        company_type_id: values.company_type.id,
        about_us_other: values.about_us_other,
        crypto_currency: values.cryptocurrency === "yes" ? true : false,
        interested_d_o: values.interested_d_o,
        attractions: attractionsSelectedArray?.attractionsSelectedArray,
        attractions_other: attractionsSelectedArray?.attractions_other,
        company_funds:
          companyFundResourcesSelectedArray?.companyFundResourcesSelectedArray,
        company_funds_other:
          companyFundResourcesSelectedArray?.company_funds_other,
        countries: values.countries.map((e) => e.id),
        platform_id: values.platforms,
        objectives: objectivesSelectedArray?.objectivesSelectedArray,
        objectives_other: objectivesSelectedArray?.objectives_other,
        crypto_currencies: cryptoCurrenciesSelectedArray,
        license_mas: values.license_mas === "no" ? false : true,
        related_crypto_blockchain:
          values.related_crypto_blockchain === "no" ? false : true,

        company_crypto_activities:
          companyCryptoActivitiesSelectedArray?.companyCryptoActivitiesSelectedArray,
        company_crypto_activities_other:
          companyCryptoActivitiesSelectedArray?.company_crypto_activities_other,
      };
      dispatch(CreateCompany(payload)).then((res) => {
        setIsLoading(false);
        if (res.type === "CreateCompany/fulfilled") {
          setCompanyId(res?.payload?.data?.id);
          next();
        }
      });
    },
  });

  return (
    <>
      {Loading ? (
        <RippleLoader />
      ) : (
        <div>
          <div className="border-b border-secondary_grey pb-2 mb-5">
            <p className="text-md">First thing First</p>
            <p className="font-ebGaramond text-2xl mb-3">
              Let's Start With Your Company Information
            </p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            {/* Check Availability */}
            <div className="space-y-3 border-b border-secondary_grey pb-5 mb-5">
              <p>1. Check your company name here</p>
              <p className="text-sm">
                Before we start incorporating your company in Singapore with
                you, you would need to make sure you have a unique company name.
              </p>
              <div className="flex items-start gap-5">
                <div className="w-full">
                  <InputWithLabel
                    className="border"
                    idFromName="company_name"
                    label="Your Company Name"
                    type="text"
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      formik.setFieldValue("isVerify", false);
                    }}
                    value={companyName}
                  />
                  <p className="text-light_grey text-sm mt-1">
                    *Company name availability is subject to final confirmation
                    from regulating authority
                  </p>
                </div>
                <button
                  type="button"
                  disabled={!companyName?.length || companyExistLoad}
                  className={`${
                    !companyName?.length || companyExistLoad
                      ? "btn-outline-disabled cursor-none"
                      : "btn-theme cursor-pointer"
                  } whitespace-nowrap !py-2.5`}
                  onClick={() => handleCheckCompanyExist(formik.setFieldValue)}
                >
                  {companyExistLoad ? "Loading...." : "Check"}
                </button>
              </div>
              {formik.errors.isVerify && formik.touched.isVerify ? (
                <InputError errorTitle={formik.errors.isVerify} />
              ) : null}
              {companyExistMessage && (
                <div
                  className={`flex items-center ${
                    companyExistError
                      ? "text-dark_extra_red bg-light_extra_red"
                      : "text-success bg-green-100"
                  } space-x-2 rounded px-5 py-3`}
                >
                  {companyExistError ? (
                    <InfoCircle size="20" />
                  ) : (
                    <CheckCircleOutline />
                  )}
                  <span className="font-normal">{companyExistMessage}</span>
                </div>
              )}
              {/* Company Type */}
              <div>
                <p className="mb-3">Please select your company type:</p>
                <SelectBox
                  id="company_type"
                  placeholder="Company Type"
                  options={companyTypesArray}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.id}
                  value={
                    companyTypesArray?.length > 0 &&
                    companyTypesArray.find(
                      (op) => op.id === formik.values.company_type_id
                    )
                  }
                  onChange={(e) => {
                    formik.setFieldValue("company_type", e);
                    formik.setFieldValue("company_type_id", e?.id);
                  }}
                  errors={
                    formik.errors.company_type && formik.touched.company_type
                  }
                  errorsText={formik.errors.company_type}
                />
              </div>
            </div>
            {/* business activity */}
            <div className="space-y-3 border-b border-secondary_grey pb-5 mb-5">
              <p>2. What’s your business activity?</p>
              <p className="text-sm">
                To incorporate in Singapore, you will need to select a Singapore
                Standard Industrial Classification (SSIC) code for your business
              </p>
              <span className="text-theme border-b border-theme inline-block cursor-pointer">
                Quick Guide
              </span>
              {/* SSIC - Company Primary Activity */}

              <SelectBox
                id="primary_activity"
                placeholder="SSIC - Company Primary Activity"
                options={businessActivitiesArray}
                isLoading={businessActivitiesLoading}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
                value={
                  businessActivitiesArray?.length > 0 &&
                  businessActivitiesArray.find(
                    (op) => op.id === formik.values.primary_activity_id
                  )
                }
                onChange={(e) => {
                  formik.setFieldValue("primary_activity", e);
                  formik.setFieldValue("primary_activity_id", e?.id);
                }}
                errorsText={formik.errors.primary_activity}
                errors={
                  formik.errors.primary_activity &&
                  formik.touched.primary_activity
                }
              />

              {/* Please describe your business activity */}
              <div>
                <InputWithLabel
                  className={
                    formik.errors.describe_business_activity &&
                    formik.touched.describe_business_activity
                      ? ` input-error `
                      : ` border `
                  }
                  idFromName="describe_business_activity"
                  label="Please describe your business activity"
                  type="text"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  value={formik.values.describe_business_activity}
                  errors={
                    formik.errors.describe_business_activity &&
                    formik.touched.describe_business_activity
                  }
                  errorsText={formik.errors.describe_business_activity}
                />
                <p className="text-light_grey text-sm mt-1">
                  A minimum 100 Characters
                </p>
              </div>
              {/* SSIC - Company Secondary Activity */}
              <SelectBox
                options={businessActivitiesArray}
                id="secondary_activity"
                placeholder={"SSIC - Company Secondary Activity"}
                isLoading={false}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
                value={
                  businessActivitiesArray?.length > 0 &&
                  businessActivitiesArray.find(
                    (op) => op.id === formik.values.secondary_activity_id
                  )
                }
                onChange={(e) => {
                  formik.setFieldValue("secondary_activity", e);
                  formik.setFieldValue("secondary_activity_id", e);
                }}
                errorsText={formik.errors.secondary_activity}
                errors={
                  formik.errors.secondary_activity &&
                  formik.touched.secondary_activity
                }
              />
              {/* Cryptocurrency Declaration */}
              <p>Cryptocurrency Declaration</p>
              <p className="text-sm">
                Is your business involved in cryptocurrency
              </p>
              <RadioButton
                label={"No"}
                idFrom={"no"}
                value={"no"}
                checked={formik.values.cryptocurrency === "no"}
                onChange={(e) => {
                  formik.handleChange(e);
                  setCryptoCurrencyRadio(false);
                }}
                name={"cryptocurrency"}
              />
              <RadioButton
                label={"Yes"}
                idFrom={"Yes"}
                value="yes"
                checked={formik.values.cryptocurrency === "yes"}
                onChange={(e) => {
                  formik.handleChange(e);
                  setCryptoCurrencyRadio(true);
                }}
                onBlur={formik.handleBlur}
                name={"cryptocurrency"}
              />
              {/* Crypto Currency */}
              {formik.values.cryptocurrency === "yes" && (
                <Cryptocurrency
                  cryptoCurrenciesData={CompanyData.crypto_currencies}
                  setCryptoCurrenciesSelectedArray={
                    setCryptoCurrenciesSelectedArray
                  }
                  formikOne={formik}
                  companyCryptoActivitiesSelectedArray={
                    companyCryptoActivitiesSelectedArray
                  }
                  setCompanyCryptoActivitiesSelectedArray={
                    setCompanyCryptoActivitiesSelectedArray
                  }
                  currenciesError={currenciesError}
                  setCurrenciesError={setCurrenciesError}
                  businessManagesError={businessManagesError}
                  setBusinessManagesError={setBusinessManagesError}
                  cryptoCurrenciesValues={createCompanyData?.crypto_currencies}
                  cryptoActivitiesValues={createCompanyData?.crypto_activities}
                  company_crypto_activities_other={
                    createCompanyData?.company_crypto_activities_other
                  }
                />
              )}
              {/* Interested */}
              <div className="border border-secondary_grey rounded-lg flex items-center gap-5 px-4 py-3">
                <MoneyApprovedCreditcardIcon />
                <div className="space-y-2">
                  <p className="">Director's & Officers (D&O)</p>
                  <p className="text-sm">
                    Protect yourself and your fellow directors from allegations
                    of wrongful acts or breaches of duty (e.g., errors &
                    omissions, breach of PDPA) while acting on behalf of the
                    company.
                  </p>
                  <div>
                    <CheckBox
                      idFrom={"interested_d_o"}
                      label={"I am interested to learn more"}
                      name={"interested_d_o"}
                      value={"yes"}
                      checked={formik.values.interested_d_o}
                      onChange={formik.handleChange}
                      className={""}
                    />
                  </div>
                  {!!formik.values.interested_d_o && (
                    <div className="flex justify-between bg-lightPrimaryShades px-4 py-2">
                      <p>Starting From</p>
                      <div className="text-right">
                        <p className="text-xs">Annual Fees</p>
                        <p>${CompanyData?.other?.directors_officers_d_o}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="space-y-3 border-b border-secondary_grey pb-5 mb-5">
              <p>3. Source of your company funds</p>
              <p className="text-sm">
                Your inputs are crucial for compliance checks.
              </p>
              <div className="space-y-2 px-3">
                <p>
                  What attracts you to incorporate your business in Singapore?
                </p>
                {attractionsError && (
                  <InputError errorTitle={attractionsError} />
                )}
                <CompanyAttractionsFunds
                  AttractionsData={CompanyData?.attractions}
                  attractionsFundsVales={createCompanyData?.attractions}
                  attractions_other={createCompanyData?.attractions_other}
                  setAttractionsSelectedArray={setAttractionsSelectedArray}
                  attractionsError={attractionsError}
                  setAttractionsError={setAttractionsError}
                />
              </div>
              <div className="space-y-2 px-3">
                <p>Please select all sources for your company's fund</p>
                {companyFundResourcesError && (
                  <InputError errorTitle={companyFundResourcesError} />
                )}

                <CompanyFundResources
                  companyFundResourcesData={CompanyData?.fund_resources}
                  companyFundResourcesVales={createCompanyData?.company_funds}
                  company_funds_other={createCompanyData?.company_funds_other}
                  setCompanyFundResourcesSelectedArray={
                    setCompanyFundResourcesSelectedArray
                  }
                  setCompanyFundResourcesError={setCompanyFundResourcesError}
                />
                {formik.errors.company_funds && formik.touched.company_funds ? (
                  <InputError errorTitle={formik.errors.company_funds} />
                ) : null}
              </div>
              <p>
                Please list up to 3 countries where your business will take
                place.{" "}
              </p>
              {/* Country */}
              <div>
                <div
                  className={`${
                    formik.errors.countries && formik.touched.countries
                      ? ` input-error `
                      : ""
                  } relative bg-white transition duration-300 rounded plus-number-inputs`}
                >
                  <ReactSelect
                    className={"text-black p-0"}
                    id="countries"
                    placeholder="Countries"
                    options={countryArray}
                    isLoading={countryLoading}
                    getOptionValue={(countryArray) => countryArray.name}
                    value={formik.values.countries}
                    isMulti
                    onChange={(e) => {
                      formik.setFieldValue("countries", e);
                      formik.setFieldValue("countryId", e?.id);
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: `1px solid ${
                          formik.errors.countries && formik.touched.countries
                            ? "red"
                            : "#0000003B"
                        }`,
                        borderRadius: "0.25rem",
                        padding: "6px 4px",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    }}
                  />
                  <label
                    htmlFor="countries"
                    className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  >
                    Countries
                  </label>
                </div>
                {formik.errors.countries && formik.touched.countries ? (
                  <InputError errorTitle={formik.errors.countries} />
                ) : null}
              </div>
            </div>
            <div className="space-y-3 border-b border-secondary_grey pb-5 mb-5">
              <p>4. Let us know more about you</p>
              <p className="text-sm">This helps us serve you better</p>
              <div className="space-y-2 px-3">
                <p>What are your next steps or objectives?</p>
                {objectivesError && <InputError errorTitle={objectivesError} />}
                <Objectives
                  objectivesData={CompanyData?.objectives}
                  setObjectivesSelectedArray={setObjectivesSelectedArray}
                  setObjectivesError={setObjectivesError}
                  objectivesVales={createCompanyData?.objectives}
                  objectives_other={createCompanyData?.objectives_other}
                />
              </div>
              <div className="space-y-2 px-3">
                <p>How did you find out about us?</p>
                {CompanyData?.platforms?.map((element) => {
                  return (
                    <RadioButton
                      label={element.title}
                      idFrom={element.title}
                      value={element.id}
                      checked={formik.values.platforms == element.id}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                      }}
                      name={"platforms"}
                    />
                  );
                })}
                {formik.values.platforms == 1 && (
                  <TextareaWithLabel
                    idFromName={"about_us_other"}
                    label={"About Us Other"}
                    className={"border"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.about_us_other}
                  />
                )}
                {formik.errors.platforms && formik.touched.platforms ? (
                  <InputError errorTitle={formik.errors.platforms} />
                ) : null}
              </div>
            </div>
            <div className="flex justify-end gap-4 pt-3">
              <button
                type="button"
                className="btn-outline flex items-center space-x-3 uppercase"
              >
                <BackArrow className="fill-theme" fillOpacity={1} />
                <span>Back</span>
              </button>
              <button
                type="submit"
                disabled={isLoading}
                className={` ${
                  isLoading ? "btn-outline-disabled" : "btn-theme"
                } flex items-center space-x-3 uppercase`}
              >
                {isLoading ? (
                  <ButtonLoader />
                ) : (
                  <>
                    <span>save & proceed on</span> <ArrowForward />
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default CompanyInformation;
