import React, { useEffect, useState } from "react";
import { Badge } from "../../../components/micro";
import Action from "../../../commons/Action";
import { User } from "../../../assets/svg/AllSvg";
import ReactSelect from "react-select";
import moment from "moment";
import {
  deleteTicket,
  getTicketsList,
  replayTicket,
} from "../../../redux/ticket/slice";
import { useDispatch, useSelector } from "react-redux";

const AllTickets = ({
  setReplyTicketModel,
  element,
  staffsList,
  staffsListLoading,
  setTicketData,
  searchValue,
  selectedPriority,
  selectedPeriod,
  pageCount,
  pageLimit,
  listStatus,
}) => {
  const [staffsArray, setStaffsArray] = useState([]);
  const dispatch = useDispatch();

  // profile
  const { getProfileData } = useSelector((store) => ({
    getProfileData: store?.authData?.adminGetProfileDataStatus?.data,
  }));

  useEffect(() => {
    if (staffsList) {
      let data = staffsList?.map((x) => ({
        ...x,
        label: x?.full_name,
      }));
      setStaffsArray(data);
    }
  }, [staffsList]);

  // Update Ticket
  const updateTickets = ({ id, payload }) => {
    dispatch(replayTicket({ id: id, data: payload })).then((res) => {
      if (res.type === "replayTicket/fulfilled") {
        dispatch(
          getTicketsList({
            search: searchValue,
            priority: selectedPriority?.value ?? "",
            period: selectedPeriod?.value ?? "",
            pageCount: pageCount,
            pageLimit: pageLimit,
            status: listStatus,
          })
        );
      }
    });
  };

  // Delete Ticket
  const handleDelete = (id) => {
    dispatch(deleteTicket(id)).then((res) => {
      if (res.type === "deleteTicket/fulfilled") {
        dispatch(
          getTicketsList({
            search: searchValue,
            priority: selectedPriority?.value ?? "",
            period: selectedPeriod?.value ?? "",
            pageCount: pageCount,
            pageLimit: pageLimit,
            status: listStatus,
          })
        );
      }
    });
  };

  return (
    <div className="hover:card border !rounded-lg space-y-4 p-4 hover:bg-light_secondary_shades">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <span
            className={`${
              element?.status === "new"
                ? "bg-light_primary_50p text-white"
                : element?.status === "on_going"
                ? "bg-primary_shades_light text-theme"
                : element?.status === "resolved"
                ? "bg-light_secondary_green text-theme"
                : "bg-light_action_disabled text-theme"
            } rounded-t-md px-4 py-1`}
          >
            {element?.status === "new"
              ? "New"
              : element?.status === "on_going"
              ? "On Going"
              : element?.status === "resolved"
              ? "Resolved"
              : "Archived"}
          </span>
          <p className="text-secondary">Ticket# {element?.unique_id}</p>
          <Badge
            value={
              element?.priority === "mid"
                ? "Mid Priority"
                : element?.priority === "low"
                ? "Low Priority"
                : "High Priority"
            }
            className={`border ${
              element?.priority === "mid"
                ? "bg-secondary_color border-secondary_color"
                : element?.priority === "low"
                ? "bg-warning_color border-warning_color"
                : "bg-danger_color border-danger_color"
            } text-white text-xs !px-2 !py-0.5`}
          />
        </div>
        <div className="flex items-center space-x-2">
          <p className="text-light_grey">
            Last Updated on{" "}
            {moment(element?.updated_at).format("DD/MM/YYYY | HH:MM")}
          </p>
          <Action
            optionList={
              <>
                <li
                  className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                  onClick={() => {
                    const payload = {
                      status:
                        element?.status === "archived"
                          ? "on_going"
                          : "archived",
                    };
                    updateTickets({ id: element?.id, payload: payload });
                  }}
                >
                  {element?.status === "archived" ? "Unarchive" : "Archive"}
                </li>
                {element?.status === "archived" && (
                  <li
                    className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                    onClick={() => {
                      handleDelete(element?.id);
                    }}
                  >
                    Delete
                  </li>
                )}
                {element?.status === "resolved" && (
                  <li
                    className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                    onClick={() => {
                      const payload = {
                        status: "on_going",
                      };
                      updateTickets({ id: element?.id, payload: payload });
                    }}
                  >
                    Reopen
                  </li>
                )}
              </>
            }
          />
        </div>
      </div>
      <div className="space-y-1">
        <p className="text-secondary ">{element?.title}</p>
        <div
          className="text-light_grey max-w-full truncate"
          dangerouslySetInnerHTML={{ __html: element?.message }}
        />
      </div>
      <div className="flex items-center justify-between">
        <div className="flex gap-3 items-center max-w-xs w-full">
          <div className="max-w-8 w-full h-8 flex justify-center items-center bg-grey rounded-full">
            <User pathClass="fill-white" fillOpacity={1} />
          </div>
          {!(
            getProfileData?.role === "super_admin" ||
            getProfileData?.role === "admin"
          ) ||
          element?.status === "resolved" ||
          element?.status === "archived" ? (
            element?.staff?.full_name
          ) : (
            <ReactSelect
              className={"w-full max-w-xs text-black p-0"}
              id="staffDropdown"
              placeholder="Assign Staff"
              options={staffsArray}
              isLoading={staffsListLoading}
              getOptionValue={(options) => options.id}
              getOptionLabel={(options) => options.label}
              value={
                staffsArray?.length > 0 &&
                staffsArray?.find((op) => {
                  return op.id === element?.staff_id;
                })
              }
              onChange={(selectedOption) => {
                const payload = {
                  staff_id: selectedOption.id,
                };
                updateTickets({ id: element?.id, payload: payload });
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #cdddeb`,
                  borderRadius: "0.3rem",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #cdddeb",
                  },
                }),
              }}
            />
          )}
        </div>
        <button
          className="text-theme border-b border-theme"
          onClick={() => {
            setTicketData(element);
            setReplyTicketModel(true);
          }}
        >
          Open Ticket
        </button>
      </div>
    </div>
  );
};

export default AllTickets;
