import React, { useState } from "react";
import { Close } from "../../assets/svg/AllSvg";
import { TextareaWithLabel } from "../../components/micro";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import RemarksTableData from "./RemarksTableData";
import EditRemark from "./EditRemark";
import ConfirmationModel from "../../commons/ConfirmationModel";

const Remarks = ({ setShowRemarks, salesData }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showEditRemarks, setShowEditRemarks] = useState(false);
  const [remarkData, setRemarkData] = useState({});

  const RemarksList = [
    {
      date: "07/11/2023",
      remark: "Full remarks content here and will be displayed",
    },
  ];
  return (
    <>
      <div
        className="model"
        onClick={() => {
          setShowRemarks(false);
        }}
      >
        <div
          className="bg-white drop-shadow-lg rounded-lg w-full max-w-xl min-h-40 py-6 px-6 mx-auto relative"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <button
            className="absolute top-5 right-5"
            onClick={() => {
              setShowRemarks(false);
            }}
          >
            <Close />
          </button>
          <h2 className="text-2xl font-ebGaramond border-b border-secondary_grey pb-4 mb-4">
            Remarks
          </h2>
          <table
            className={`w-full text-sm overflow-x-auto min-w-[400px] custom-scroll mb-5`}
          >
            {/* column headers */}
            <thead className="text-sm text-gray-700 bg-secondary_light_shades font-semibold ">
              <tr>
                <th
                  scope="col"
                  className="px-5 py-3 border-b font-semibold text-left bg-secondary_light_shades whitespace-nowrap rounded-tl-md"
                >
                  No.
                </th>
                <th
                  scope="col"
                  className="px-5 py-2 border-b font-semibold text-left bg-secondary_light_shades"
                >
                  Remark
                </th>
                <th
                  scope="col"
                  className="px-5 py-2 border-b font-semibold bg-secondary_light_shades"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-5 py-2 border-b font-semibold bg-secondary_light_shades"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="relative">
              {RemarksList?.map((element, index) => {
                return (
                  <RemarksTableData
                    element={element}
                    index={index}
                    remarkData={remarkData}
                    setRemarkData={setRemarkData}
                    setConfirmDelete={setConfirmDelete}
                    setShowEditRemarks={setShowEditRemarks}
                    key={index}
                  />
                );
              })}
            </tbody>
          </table>
          <div>
            <Formik
              enableReinitialize={true}
              initialValues={{
                remarks: "",
              }}
              validationSchema={Yup.object().shape({
                remarks: Yup.string().required("Please Enter Remarks"),
              })}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit} className="space-y-3">
                  <TextareaWithLabel
                    className={
                      errors.remarks && touched.remarks
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="remarks"
                    label="Add Remarks"
                    row={1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.remarks}
                    errors={errors.remarks && touched.remarks}
                    errorsText={errors.remarks}
                  />
                  <button type="submit" className="btn-theme w-full">
                    Add
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      {/* Confirm Delete Popup */}
      {confirmDelete && (
        <ConfirmationModel
          confirmText={"Delete Remarks"}
          isLoading={false}
          onClose={() => {
            setConfirmDelete(false);
          }}
          onConfirm={() => {
            setConfirmDelete(false);
          }}
        />
      )}

      {/* Edit Remark */}
      {showEditRemarks && (
        <EditRemark
          setShowEditRemarks={setShowEditRemarks}
          remarkData={remarkData}
        />
      )}
    </>
  );
};

export default Remarks;
