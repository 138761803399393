import React, { useContext, useEffect, useState } from "react";
import {
  ArrowForward,
  Avatar,
  BackArrow,
  Edit,
  Info,
  Plus,
  Trash,
} from "../../../../assets/svg/AllSvg";
import { Badge, InformationTag, Tooltip } from "../../../../components/micro";
import { InfoCircle } from "iconsax-react";
import AddDirector from "./AddDirector";
import AddShareholder from "./AddShareholder";
import CompanyContext from "../CompanyContext";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  DeleteDirectorShareholder,
  getCompanyById,
  getCorporateSecretary,
} from "../../../../redux/companies/slice";
import { toast } from "react-toastify";
import RippleLoader from "../../../../commons/RippleLoader";
import { Link } from "react-router-dom";

const LocalResidentDirector = ({
  company_id,
  shareholders,
  resident_directors,
}) => {
  const [showDirectorModel, setShowDirectorModel] = useState(false);
  const [showShareholderModel, setShowShareholderModel] = useState(false);
  const [directorsArray, setDirectorsArray] = useState([]);
  const [shareholderArray, setShareholderArray] = useState([]);
  const [directorsError, setDirectorsError] = useState("");
  const [shareholderError, setShareholderError] = useState("");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [isEdit, setIsEdit] = useState({});
  const dispatch = useDispatch();
  const { prev, next } = useContext(CompanyContext);

  // Selector
  const { CorporateSecretaryDetails, loading } = useSelector((store) => ({
    loading: store?.companiesData?.loading,
    CorporateSecretaryDetails:
      store?.companiesData?.getCorporateSecretaryStatus?.data?.data,
  }));

  useEffect(() => {
    dispatch(getCorporateSecretary());
  }, []);

  useEffect(() => {
    setShareholderArray([...shareholderArray, ...shareholders]);
    setDirectorsArray([...directorsArray, ...resident_directors]);
  }, [shareholders, resident_directors]);

  // DeleteDirectorShareholder
  const handleDelete = (id, type) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "error",
      type: "warning",
      html: `You won't be able to revert this!`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((response) => {
      if (response.isConfirmed === true) {
        setDeleteLoader(true);
        dispatch(DeleteDirectorShareholder(id)).then((response) => {
          setDeleteLoader(false);
          if (response.type === "DeleteDirectorShareholder/fulfilled") {
            toast.success(response.payload.message);
            if (type === "shareholder") {
              const updatedItems = shareholderArray.filter(
                (item) => item.id !== id
              );
              setShareholderArray(updatedItems);
            } else if (type === "directors") {
              const updatedItems = directorsArray.filter(
                (item) => item.id !== id
              );
              setDirectorsArray(updatedItems);
            }
          }
        });
      }
    });
  };

  const handleSave = () => {
    if (!directorsArray.length) {
      setDirectorsError(true);
      return;
    }
    if (!shareholderArray.length) {
      setShareholderError(true);
      return;
    }
    dispatch(getCompanyById(company_id));
    next();
  };

  useEffect(() => {
    if (directorsArray.length) {
      setDirectorsError(false);
    }
    if (shareholderArray.length) {
      setShareholderError(false);
    }
  }, [directorsArray, shareholderArray]);

  return (
    <>
      {loading ? (
        <RippleLoader />
      ) : (
        <div className="space-y-5">
          <div className="flex space-x-4">
            <Info />
            <div className="space-y-3">
              <p>
                Singapore law mandates that all incorporated companies must have
                at least one local resident director and one shareholder.
              </p>
              <p>
                You can use your own Local Director (Singaporean Citizen, PR or
                Entrepass holder) or sign up for our Nominee Local Director
                service.
              </p>
              <Link
                target="_blank"
                to={
                  "https://www.acra.gov.sg/how-to-guides/foreigners-registering-a-business-in-singapore/requirements-for-local-residency"
                }
                className="text-theme border-b border-theme"
              >
                Read More
              </Link>
            </div>
          </div>
          <div className="space-y-3 border-b border-secondary_grey pb-5">
            <p className="text-lg">1. Directors</p>
            <p>
              An email invitation will be sent to company members for identity
              verification.
            </p>
            {directorsError && (
              <InformationTag
                text={
                  "At least 1 Operational Director (Non Nominee Director) must be appointed"
                }
                type={"error"}
              />
            )}
            <div className="grid grid-cols-4 gap-5 bg-light_secondary_shades rounded-lg py-4 px-5">
              <div className="border-r-2 pr-4">
                <p className="text-xl font-ebGaramond font-semibold mb-2">
                  Directors
                </p>
                <p className="text-light_grey font-medium text-sm">
                  At least 1 local resident director required
                </p>
              </div>
              <div className="col-span-3">
                <div
                  className={`space-y-4 max-h-80 overflow-y-auto mb-4 ${
                    directorsArray?.length && "p-3"
                  }`}
                >
                  {directorsArray?.map((element) => {
                    return (
                      <div className="card bg-white border flex justify-between items-center">
                        <div className="flex items-center space-x-3">
                          <Avatar width={45} height={45} />
                          <div>
                            <p className="text-lg font-medium">
                              {element?.user?.full_name}
                            </p>
                            <p className="text-sm">{element?.user?.email}</p>
                          </div>
                        </div>
                        {element?.main_signee ? (
                          <div className="flex items-center space-x-3">
                            <Badge
                              value={"Main Signee"}
                              className={
                                "border border-theme text-theme text-sm font-semibold px-4 !py-1"
                              }
                            />
                            <button
                              className="p-3"
                              onClick={() => {
                                setShowDirectorModel(true);
                                setIsEdit(element);
                              }}
                            >
                              <Edit width="16" height="20" />
                            </button>
                          </div>
                        ) : (
                          <button
                            className="p-3"
                            disabled={deleteLoader}
                            onClick={() => {
                              handleDelete(element?.id, "directors");
                            }}
                          >
                            <Trash width="16" height="20" />
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
                <button
                  className="btn-outline uppercase flex items-center ml-3"
                  onClick={() => {
                    setShowDirectorModel(true);
                    setIsEdit({});
                  }}
                >
                  <Plus className="fill-theme mr-2" />
                  Add director
                </button>
              </div>
            </div>
          </div>
          <div className="space-y-3 border-b border-secondary_grey pb-5">
            <p className="text-lg">2. Shareholders</p>
            <p>
              An email invitation will be sent to company members for identity
              verification.
            </p>
            {shareholderError && (
              <InformationTag
                text={"At least 1 Shareholder must be appointed"}
                type={"error"}
              />
            )}
            <div className="grid grid-cols-4 gap-5 bg-light_secondary_shades rounded-lg py-5 px-6">
              <div className="border-r-2 pr-4">
                <p className="text-xl font-ebGaramond font-semibold flex items-center mb-2">
                  Shareholders
                  <Tooltip
                    label={
                      <>
                        <InfoCircle className="text-theme ml-1" />
                      </>
                    }
                    tooltipLabel={
                      <>
                        <div className="whitespace-nowrap">
                          Pricing:
                          <li>
                            1 Shareholder: $
                            {
                              CorporateSecretaryDetails?.detail?.tiered_pricing
                                ?.shareholder_1
                            }
                            /year
                          </li>
                          <li>
                            2 Shareholders: $
                            {
                              CorporateSecretaryDetails?.detail?.tiered_pricing
                                ?.shareholders_2
                            }
                            /year
                          </li>
                          <li>
                            3 - 5 Shareholders: $
                            {
                              CorporateSecretaryDetails?.detail?.tiered_pricing
                                ?.shareholders_3_5
                            }
                            /year
                          </li>
                          <li>
                            6 - 9 Shareholders: $
                            {
                              CorporateSecretaryDetails?.detail?.tiered_pricing
                                ?.shareholders_6_9
                            }
                            /year
                          </li>
                          <li>
                            10 - 20 Shareholders: $
                            {
                              CorporateSecretaryDetails?.detail?.tiered_pricing
                                ?.shareholders_10_20
                            }
                            /year
                          </li>
                          <li>
                            21- 30 Shareholders: $
                            {
                              CorporateSecretaryDetails?.detail?.tiered_pricing
                                ?.shareholders_21_30
                            }
                            /year
                          </li>
                          <li>{`>30 Shareholders: $${CorporateSecretaryDetails?.detail?.tiered_pricing?.greater_than_30_shareholders}/year`}</li>
                        </div>
                      </>
                    }
                  />
                </p>
                <p className="text-light_grey font-medium text-sm">
                  Starting at SGD 300 per year for corporate secretary services
                </p>
              </div>
              <div className="col-span-3">
                <div
                  className={`space-y-4 max-h-80 overflow-y-auto mb-4 ${
                    shareholderArray?.length && "p-3"
                  }`}
                >
                  {shareholderArray?.map((element) => {
                    return (
                      <div className="card bg-white border flex justify-between items-center">
                        <div className="flex items-center space-x-3">
                          <Avatar width={45} height={45} />
                          <div>
                            <p className="text-lg font-medium">
                              {element?.user?.full_name}
                            </p>
                            <p className="text-sm">{element?.user?.email}</p>
                          </div>
                        </div>
                        <button
                          className="p-3"
                          disabled={deleteLoader}
                          onClick={() => {
                            handleDelete(element?.id, "shareholder");
                          }}
                        >
                          <Trash width="16" height="20" />
                        </button>
                      </div>
                    );
                  })}
                </div>
                <button
                  className="btn-outline uppercase flex items-center"
                  onClick={() => {
                    setShowShareholderModel(true);
                    setIsEdit({});
                  }}
                >
                  <Plus className="fill-theme mr-2" />
                  Add shareholder
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4 pt-3">
            <button
              type="button"
              className="btn-outline flex items-center space-x-3 uppercase"
              onClick={() => prev()}
            >
              <BackArrow className="fill-theme" fillOpacity={1} />
              <span>Back</span>
            </button>
            <button
              type="submit"
              className="btn-theme flex items-center space-x-3 uppercase"
              onClick={handleSave}
            >
              <span>save & proceed on</span> <ArrowForward />
            </button>
          </div>
        </div>
      )}
      {showDirectorModel && (
        <AddDirector
          setShowDirectorModel={setShowDirectorModel}
          showDirectorModel={showDirectorModel}
          company_id={company_id}
          setDirectorsArray={setDirectorsArray}
          directorsArray={directorsArray}
          nominee={false}
          isEdit={isEdit}
        />
      )}
      {showShareholderModel && (
        <AddShareholder
          setShowShareholderModel={setShowShareholderModel}
          shareholderArray={shareholderArray}
          setShareholderArray={setShareholderArray}
          company_id={company_id}
        />
      )}
    </>
  );
};

export default LocalResidentDirector;
