import React, { useState } from "react";
import { Close } from "../../../assets/svg/AllSvg";
import DebounceSearch from "../../../commons/DebounceSearch";

const NewChatModel = ({ onClose, setNewChatName }) => {
  const [searchValue, setSearchValue] = useState("");

  const chatData = ["Name 1", "Name 2", "Name 3", "Name 4", "Name 5", "Name 6"];

  return (
    <div className="model_right" onClick={onClose}>
      <div
        className="bg-white drop-shadow-lg w-full max-w-xs space-y-5 min-h-40 py-8 px-6 relative"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex justify-end">
          <button className="flex items-center gap-2" onClick={onClose}>
            <Close className="w-3 h-3" pathClass="fill-theme" fillOpacity={1} />
            <p className="text-theme uppercase text-sm">close</p>
          </button>
        </div>
        <DebounceSearch
          search={searchValue}
          handleChange={setSearchValue}
          placeholder={"Search..."}
        />
        <div>
          {chatData.map((element) => (
            <button
              type="button"
              className="py-2 px-5 block w-full text-left rounded-md duration-300 hover:bg-sub_grey"
              onClick={() => setNewChatName(element)}
            >
              {element}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewChatModel;
