import React, { useContext, useState } from "react";
import CompanyContext from "./CompanyContext";
import { BackArrow, CreateNewFolderIcon } from "../../../assets/svg/AllSvg";
import ConfirmationModel from "../../../commons/ConfirmationModel";
import { useNavigate } from "react-router-dom";

const Confirmation = ({ companyId }) => {
  const [confirmCreate, setConfirmCreate] = useState(false);
  const navigate = useNavigate();
  const { prev } = useContext(CompanyContext);

  const onConfirm = () => {
    console.log("companyId", companyId);
    navigate("/companies");
  };

  return (
    <>
      <div className="border-b border-secondary_grey pb-2 mb-5">
        <p className="text-md mb-1">You are all set!</p>
        <p className="font-ebGaramond text-2xl mb-3">
          If all information are correct and good to go :)
        </p>
      </div>
      <div className="space-y-2">
        <p>Once all the information are correct and are ready</p>
        <p className="text-sm">You can go ahead creating this company!</p>
      </div>
      <div className="flex justify-end gap-4 pt-3">
        <button
          type="button"
          className="btn-outline flex items-center space-x-3 uppercase"
          onClick={() => prev()}
        >
          <BackArrow className="fill-theme" fillOpacity={1} />
          <span>Back</span>
        </button>
        <button
          type="submit"
          className="btn-theme flex items-center space-x-3 uppercase"
          onClick={() => {
            setConfirmCreate(true);
          }}
        >
          <span>Create</span> <CreateNewFolderIcon />
        </button>
      </div>
      {/* Confirm Create */}
      {confirmCreate && (
        <ConfirmationModel
          isLoading={false}
          confirmText={"Create"}
          onClose={(e) => {
            setConfirmCreate(false);
          }}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default Confirmation;
