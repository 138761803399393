import React from "react";
import { TaskList } from "../../assets/svg/AllSvg";
import AllGood from "../../commons/AllGood";
import { Link } from "react-router-dom";
import CustomTable from "../../commons/CustomTable";
import moment from "moment/moment";

const OnGoingTask = () => {
  const onGoingData = {
    data: [
      {
        taskName: "task 1",
        dueDate: new Date(),
      },
      {
        taskName: "task 2",
        dueDate: new Date(),
      },
      {
        taskName: "task 3",
        dueDate: new Date(),
      },
    ],
    current_page: 1,
    per_page: 10,
    last_page: 1,
  };

  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b font-semibold text-left bg-secondary_light_shades whitespace-nowrap rounded-tl-md"
        >
          No.
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-secondary_light_shades"
        >
          Task
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-secondary_light_shades"
        >
          Due Date
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-secondary_light_shades"
        >
          Actions
        </th>
      </>
    );
  };

  const DataRows = () => {
    return (
      <>
        {(onGoingData?.data || [])?.map((element, index) => (
          <tr
            className={
              "border-b text-sm bg-light_secondary hover:bg-secondary_light_shades"
            }
            key={element?.id}
          >
            <td className="px-5 py-3">
              {(onGoingData?.current_page - 1) * onGoingData?.per_page +
                index +
                1}
            </td>
            <td className="px-5 py-3">{element?.taskName}</td>
            <td className="px-5 py-3">
              {moment(element?.dueDate).format("DD/MM/YYYY")}
            </td>
            <td className="px-5 py-3">
              <Link to={"/"} className="text-theme border-b border-theme">
                View
              </Link>
            </td>
          </tr>
        ))}
      </>
    );
  };
  return (
    <>
      <p className="text-lg flex items-center font-ebGaramond font-semibold mb-2">
        <TaskList className="fill-theme mr-2" width={24} /> On-Going Task
      </p>
      {onGoingData?.data?.length > 0 ? (
        <CustomTable
          columnHeaders={<ColumnHeaders />}
          dataRows={<DataRows />}
          data={onGoingData}
          overflowVisibleLg={false}
          // loader
          loading={false}
          showPagination={false}
        />
      ) : (
        <AllGood />
      )}
      <div className="text-right mt-5">
        <Link
          to={"/"}
          className="text-theme border-b border-theme text-sm font-bold uppercase text-right w-full"
        >
          View All
        </Link>
      </div>
    </>
  );
};

export default OnGoingTask;
