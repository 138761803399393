import React, { useEffect, useState } from "react";
import Breadcrumb from "../../commons/Breadcrumb";
import ReactSelect from "react-select";
import CustomTable from "../../commons/CustomTable";
import Action from "../../commons/Action";
import { Close } from "../../assets/svg/AllSvg";
import ConfirmationModel from "../../commons/ConfirmationModel";

const Notifications = () => {
  const [selectedBulkAction, setSelectedBulkAction] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [viewNotification, setViewNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({});
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const bulkAction = [
    { value: "read", label: "Read" },
    { value: "delete", label: "Delete" },
  ];

  // Table Data
  const customersList = {
    data: [
      {
        message: "Notification Message 1",
        id: "S0001",
        dateTime: "27/03/2023 | 14:00 PM",
        checked: false,
      },
      {
        message: "Notification Message 2",
        id: "S0002",
        dateTime: "27/03/2023 | 14:00 PM",
        checked: false,
      },
      {
        message: "Notification Message 3",
        id: "S0003",
        dateTime: "27/03/2023 | 14:00 PM",
        checked: false,
      },
      {
        message: "Notification Message 4",
        id: "S0004",
        dateTime: "27/03/2023 | 14:00 PM",
        checked: false,
      },
    ],
    current_page: 1,
    per_page: 10,
    last_page: 1,
    total: 4,
  };

  const [customersListCheck, setCustomersListCheck] = useState(
    customersList?.data
  );
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    const updatedItems = customersListCheck.map((item) => ({
      ...item,
      checked: newSelectAll,
    }));
    setCustomersListCheck(updatedItems);
  };

  const handleIndividualChange = (id) => {
    const updatedItems = customersListCheck.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setCustomersListCheck(updatedItems);

    // Check if all individual checkboxes are checked to update "Select All" checkbox
    const allChecked = updatedItems.every((item) => item.checked);
    setSelectAll(allChecked);
  };

  useEffect(() => {
    const d = customersListCheck.filter((item) => item.checked);
    // console.log(d);
  }, [customersListCheck]);

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b font-semibold text-left bg-primary_shades_light whitespace-nowrap rounded-tl"
        >
          <div className="flex items-center">
            <input
              type="checkbox"
              className="w-4 h-4 mr-2"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />{" "}
            No.
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light"
        >
          Notification
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Date/Time
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Action
        </th>
      </>
    );
  };
  // Table Row
  const DataRows = () => {
    return (
      <>
        {(customersListCheck || [])?.map((element, index) => (
          <tr
            className={"border-b text-sm hover:bg-secondary_light_shades"}
            key={element?.id}
          >
            <td className="px-5 py-3">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={element?.checked}
                  className="w-4 h-4 mr-2"
                  onChange={() => handleIndividualChange(element?.id)}
                />
                {(customersList?.current_page - 1) * customersList?.per_page +
                  index +
                  1}
              </div>
            </td>
            <td className="px-5 py-3">
              <span
                className="text-[#303F9F] border-b border-[#303F9F] cursor-pointer"
                onClick={() => {
                  setNotificationData(element);
                  setViewNotification(true);
                }}
              >
                {element?.message}
              </span>
            </td>
            <td className="px-5 py-3">{element?.dateTime}</td>
            <td className="px-5 py-3">
              <Action
                optionList={
                  <>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() => {
                        setNotificationData(element);
                        setViewNotification(true);
                      }}
                    >
                      View
                    </li>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() => {
                        setNotificationData(element);
                        setConfirmDelete(true);
                      }}
                    >
                      Delete
                    </li>
                  </>
                }
              />
            </td>
          </tr>
        ))}
      </>
    );
  };
  return (
    <>
      <div className="space-y-5">
        <div>
          <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
            Notifications
          </h6>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbActive={"Notifications"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
        <ReactSelect
          className={"w-full max-w-xs text-black p-0"}
          id="bulkAction"
          placeholder="Bulk Action"
          options={bulkAction}
          getOptionValue={(bulkActionList) => bulkActionList.value}
          value={selectedBulkAction}
          onChange={(e) => {
            setSelectedBulkAction(e);
          }}
          styles={{
            control: (base) => ({
              ...base,
              border: `1px solid #cdddeb`,
              borderRadius: "0.3rem",
              paddingTop: "2px",
              paddingBottom: "2px",
              boxShadow: "none",
              "&:hover": {
                border: "1px solid #cdddeb",
              },
            }),
          }}
        />
        <div className="w-full bg-white border rounded">
          <CustomTable
            columnHeaders={<ColumnHeaders />}
            dataRows={<DataRows />}
            data={customersList}
            overflowVisibleLg={true}
            // loader
            loading={false}
            showPagination={true}
            // showLimit
            setPaginationCurrentLimit={setPageLimit}
            paginationCurrentLimit={pageLimit}
            // paginationData
            total={customersList?.total}
            current={pageCount}
            paginationData={(crPage) => setPageCount(crPage)}
          />
        </div>
      </div>
      {confirmDelete && (
        <ConfirmationModel
          confirmText={"Delete"}
          isLoading={false}
          onClose={() => {
            setConfirmDelete(false);
          }}
          onConfirm={() => {
            setConfirmDelete(false);
          }}
        />
      )}
      {viewNotification && (
        <div
          className="model"
          onClick={() => {
            setViewNotification(false);
          }}
        >
          <div
            className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-sm min-h-40 py-8 px-6 mx-auto relative"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <button
              className="absolute top-5 right-5"
              onClick={() => {
                setViewNotification(false);
              }}
            >
              <Close />
            </button>
            <h2 className="text-2xl font-ebGaramond mb-3">
              {notificationData?.message}
            </h2>
          </div>
        </div>
      )}
    </>
  );
};

export default Notifications;
