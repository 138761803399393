import React, { useState } from "react";
import {
  FileDownload,
  ImgIcon,
  PDFFileIcon,
  UpArrow,
} from "../../assets/svg/AllSvg";

const Documents = ({ chatDocuments }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="px-4">
      <div className="bg-secondary_light_shades rounded-2xl h-full space-y-3 py-4 px-5">
        <div className="flex space-x-2">
          <p className="text-dark_primary">Documents</p>
          <span className="w-6 h-6 bg-green-600 text-white rounded-full flex justify-center items-center">
            {chatDocuments?.length}
          </span>
        </div>
        <button
          className="flex justify-between items-center w-full"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <p className="text-theme">Files</p>
          {isOpen ? <UpArrow /> : <UpArrow className="rotate-180" />}
        </button>
        {isOpen && (
          <>
            {chatDocuments.map((element, index) => {
              return (
                <div className="flex justify-between items-center" key={index}>
                  <div className="flex items-center space-x-2 w-full">
                    {element?.document?.document_type === "doc" ? (
                      <PDFFileIcon />
                    ) : (
                      <ImgIcon />
                    )}
                    <p className="truncate text-sm max-w-[calc(100%-20px)]">
                      {element?.document?.name}
                    </p>
                  </div>

                  <a
                    href={element?.document?.url}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-block"
                  >
                    <FileDownload />
                  </a>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default Documents;
