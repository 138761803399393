import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Delete Director / Shareholder
export const DeleteDirectorShareholder = createAsyncThunk(
  "DeleteDirectorShareholder",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`users/${id}`);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Existing Users
export const existingUsers = createAsyncThunk(
  "existingUsers",
  async (type, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`existing-users?type=${type}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Director / Shareholder
export const addDirectorShareholder = createAsyncThunk(
  "addDirectorShareholder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`users`, data);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Update User
export const updateUser = createAsyncThunk(
  "updateUser",
  async ({ type, id }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`users/${id}`, { type });
      return response.data;
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
      return rejectWithValue(error.response?.data);
    }
  }
);

// Get Nominee Directors Details
export const getNomineeDirectorsDetails = createAsyncThunk(
  "getNomineeDirectorsDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(
        `master-settings?slug=nominee_director`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Corporate Secretary
export const getCorporateSecretary = createAsyncThunk(
  "corporate_secretary",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(
        `master-settings?slug=corporate_secretary`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const OrganisationSlice = createSlice({
  name: "Organisation",
  initialState: initialStates,
  extraReducers: {
    // Delete Director Shareholder
    [DeleteDirectorShareholder.pending]: (state) => {
      state.loading = true;
    },
    [DeleteDirectorShareholder.fulfilled]: (state, action) => {
      state.loading = false;
      state.deleteDirectorShareholderStatus.data = action.payload;
      state.error = false;
    },
    [DeleteDirectorShareholder.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Existing Users
    [existingUsers.pending]: (state) => {
      state.existingUsersStatus.loading = true;
    },
    [existingUsers.fulfilled]: (state, action) => {
      state.existingUsersStatus.loading = false;
      state.existingUsersStatus.data = action.payload;
      state.error = false;
    },
    [existingUsers.rejected]: (state) => {
      state.existingUsersStatus.loading = false;
      state.error = true;
    },
    // Add Director Shareholder
    [addDirectorShareholder.pending]: (state) => {
      state.addDirectorShareholderStatus.loading = true;
    },
    [addDirectorShareholder.fulfilled]: (state, action) => {
      state.addDirectorShareholderStatus.loading = false;
      state.addDirectorShareholderStatus.data = action.payload;
      state.error = false;
    },
    [addDirectorShareholder.rejected]: (state) => {
      state.addDirectorShareholderStatus.loading = false;
      state.error = true;
    },

      // Add Director Shareholder
      [updateUser.pending]: (state) => {
        state.updateUserStatus.loading = true;
      },
      [updateUser.fulfilled]: (state, action) => {
        state.updateUserStatus.loading = false;
        state.updateUserStatus.data = action.payload;
        state.error = false;
      },
      [updateUser.rejected]: (state) => {
        state.updateUserStatus.loading = false;
        state.error = true;
      },

    // Get Nominee Directors Details
    [getNomineeDirectorsDetails.pending]: (state) => {
      state.loading = true;
    },
    [getNomineeDirectorsDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.getNomineeDirectorsDetailsStatus.data = action.payload;
      state.error = false;
    },
    [getNomineeDirectorsDetails.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // get Corporate Secretary Details
    [getCorporateSecretary.pending]: (state) => {
      state.loading = true;
    },
    [getCorporateSecretary.fulfilled]: (state, action) => {
      state.loading = false;
      state.getCorporateSecretaryStatus.data = action.payload;
      state.error = false;
    },
    [getCorporateSecretary.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});
const { reducer } = OrganisationSlice;
export default reducer;
