import React from "react";
import { TaskAltFilled } from "../assets/svg/AllSvg";

const AllGood = () => {
  return (
    <>
      <div className="flex flex-col justify-center items-center bg-light_secondary_shades rounded h-[calc(100%-32px)]">
        <TaskAltFilled className="mb-1" />
        <p>You are all good!</p>
      </div>
    </>
  );
};

export default AllGood;
