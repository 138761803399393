import React, { useState } from "react";
import Breadcrumb from "../../commons/Breadcrumb";
import { Plus } from "../../assets/svg/AllSvg";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import DebounceSearch from "../../commons/DebounceSearch";
import { Badge, SelectBox } from "../../components/micro";
import CustomTable from "../../commons/CustomTable";
import Action from "../../commons/Action";
import ConfirmationModel from "../../commons/ConfirmationModel";

const Broadcast = () => {
  const [searchValue, setSearchValue] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [selectedUserTypeStatus, setSelectedUserTypeStatus] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [broadcastData, setBroadcastData] = useState({});
  const navigate = useNavigate();

  //   User Type Dropdown
  const userTypeDropdown = [
    { value: "corporate_secretary", label: "Corporate secretary" },
    { value: "corporate_accounting", label: "Corporate / Accounting" },
  ];

  //   Status Dropdown
  const statusDropdown = [
    { value: "scheduled", label: "Scheduled" },
    { value: "sent", label: "Sent" },
  ];

  // Table Data
  const broadcastList = {
    data: [
      {
        id: "01",
        subject: "Happy New Year",
        content: "Content Here",
        types: "Corporate Secretary",
        sendDate: "24/07/2023",
        status: "SCHEDULED",
      },
      {
        id: "01",
        subject: "Mid Year Promo",
        content: "Content Here",
        types: "Corporate / Accounting",
        sendDate: "24/07/2023",
        status: "SCHEDULED",
      },
      {
        id: "03",
        subject: "X’mas Greeting",
        content: "Content Here",
        types: "Corporate / Accounting",
        sendDate: "24/07/2023",
        status: "SENT",
      },
    ],
    current_page: 1,
    per_page: 10,
    last_page: 1,
    total: 3,
  };

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b font-semibold text-left bg-primary_shades_light whitespace-nowrap rounded-tl"
        >
          No.
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light"
        >
          Subject
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Content
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Types
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Send Date
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Status
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Action
        </th>
      </>
    );
  };
  // Table Row
  const DataRows = () => {
    return (
      <>
        {(broadcastList?.data || [])?.map((element, index) => (
          <tr
            className={"border-b text-sm hover:bg-secondary_light_shades"}
            key={element?.id}
          >
            <td className="px-5 py-3">
              {(broadcastList?.current_page - 1) * broadcastList?.per_page +
                index +
                1}
            </td>
            <td className="px-5 py-3">{element?.subject}</td>
            <td className="px-5 py-3">
              <p className="max-w-[100px] truncate">{element?.content}</p>
            </td>
            <td className="px-5 py-3">
              <div className="flex justify-start">
                {
                  <Badge
                    className={`capitalize ${
                      element?.types === "Corporate Secretary"
                        ? "border-success text-success"
                        : "border-theme text-theme"
                    }`}
                    value={element?.types}
                  />
                }
              </div>
            </td>
            <td className="px-5 py-3">{element?.sendDate}</td>
            <td className="px-5 py-3">
              <div className="flex justify-start">
                {
                  <Badge
                    className={`text-white capitalize ${
                      element?.status === "SCHEDULED"
                        ? "border-theme bg-theme"
                        : "border-success bg-success"
                    }`}
                    value={element?.status}
                  />
                }
              </div>
            </td>
            <td className="px-5 py-3">
              <Action
                setConfirmDelete={setConfirmDelete}
                optionList={
                  <>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondaryLightShades transition duration-300 cursor-pointer"
                      onClick={() =>
                        navigate("/create-edit-broadcast", {
                          state: {
                            element: element,
                          },
                        })
                      }
                    >
                      View/Edit
                    </li>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondaryLightShades transition duration-300 cursor-pointer"
                      onClick={() => {
                        setBroadcastData(element);
                        setConfirmDelete(true);
                      }}
                    >
                      Delete
                    </li>
                  </>
                }
              />
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex justify-between items-start">
          <div>
            <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
              Broadcast
            </h6>
            <Breadcrumb
              breadCrumbParent={"Home"}
              breadCrumbActive={"Broadcast"}
              breadCrumbTitleKey={"/"}
              classes=""
            />
          </div>
          <button
            className="btn-theme flex items-center uppercase"
            onClick={() => {
              navigate("/create-edit-broadcast");
            }}
          >
            <Plus className="mr-2" /> Add Broadcast
          </button>
        </div>
        <div className="flex items-center space-x-3">
          <DebounceSearch
            search={searchValue}
            handleChange={setSearchValue}
            placeholder={"Search..."}
          />
          <SelectBox
            className={"w-full max-w-xs"}
            id="userTypeDropdown"
            placeholder="Select User Type"
            options={userTypeDropdown}
            isLoading={false}
            getOptionValue={(selectedUserTypeStatus) =>
              selectedUserTypeStatus.value
            }
            value={selectedUserTypeStatus}
            onChange={(e) => {
              setSelectedUserTypeStatus(e);
            }}
          />
          <SelectBox
            className={"w-full max-w-xs"}
            id="status"
            placeholder="Select Status"
            options={statusDropdown}
            isLoading={false}
            getOptionValue={(selectedStatus) => selectedStatus.value}
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e);
            }}
          />
        </div>
        <div className="w-full bg-white border rounded">
          <CustomTable
            columnHeaders={<ColumnHeaders />}
            dataRows={<DataRows />}
            data={broadcastList}
            overflowVisibleLg={true}
            // loader
            loading={false}
            showPagination={true}
            // showLimit
            setPaginationCurrentLimit={setPageLimit}
            paginationCurrentLimit={pageLimit}
            // paginationData
            total={broadcastList?.total}
            current={pageCount}
            paginationData={(crPage) => setPageCount(crPage)}
          />
        </div>
      </div>

      {confirmDelete && (
        <ConfirmationModel
          confirmText={"Delete"}
          isLoading={false}
          onClose={() => {
            setConfirmDelete(false);
          }}
          onConfirm={() => {
            setConfirmDelete(false);
          }}
        />
      )}
    </>
  );
};

export default Broadcast;
