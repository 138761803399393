import React, { useContext, useEffect, useState } from "react";
import {
  ArrowForward,
  BackArrow,
  HouseSearch,
} from "../../../../assets/svg/AllSvg";
import { useDispatch, useSelector } from "react-redux";
import { getCountryData } from "../../../../redux/address/slice";
import ReactSelect from "react-select";
import { InputWithLabel, RadioButton } from "../../../../components/micro";
import { Formik } from "formik";
import * as Yup from "yup";
import { InputError } from "../../../../commons/MicroComponents";
import CompanyContext from "../CompanyContext";
import {
  Address,
  createAddress,
  getCompanyById,
} from "../../../../redux/companies/slice";
import ButtonLoader from "../../../../commons/ButtonLoader";
import { Link } from "react-router-dom";

const RegisteredOfficeAddress = ({
  companyId,
  addressCompanyActivitiesPopup,
  setAddressCompanyActivitiesPopup,
  companyByIdData,
  addresses,
}) => {
  const dispatch = useDispatch();
  const { prev, next } = useContext(CompanyContext);

  const [countryArray, setCountryArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addressTypeValue, setAddressTypeValue] = useState();

  const { countryData, AddressData } = useSelector((store) => ({
    // Country Data
    countryData: store?.addressData?.getCountryDataStatus?.data,
    AddressData: store?.companiesData?.AddressStatus?.data?.data,
  }));

  useEffect(() => {
    dispatch(getCountryData());
    {
      !addressCompanyActivitiesPopup && dispatch(Address());
    }
  }, [dispatch]);

  useEffect(() => {
    if (countryData?.data) {
      let data = countryData?.data?.map((x) => ({ ...x, label: x.phonecode }));
      setCountryArray(data);
    }
  }, [countryData]);

  const relevantAddress = companyByIdData?.addresses?.find(
    (el) => el.type === "registered" && el.sub_type === "business_operational"
  );

  useEffect(() => {
    if (addresses?.length) {
      const AddressTypeValue = {
        label: addresses[0]?.address_type,
        value: addresses[0]?.address_type,
      };
      setAddressTypeValue(AddressTypeValue);
    }
  }, [addresses]);

  return (
    <div className="space-y-3">
      {!addressCompanyActivitiesPopup && (
        <div className="border-b border-secondary_grey pb-3">
          <p className="mb-3">1. Registered Office Address</p>
          <div className="flex space-x-5 bg-light_background_default rounded-lg py-5 px-6">
            <HouseSearch />
            <div>
              <span className="px-2.5 py-1 text-sm bg-light_green text-white inline-block rounded-full mb-2">
                Your EiBiz Registered Address
              </span>
              <p className="text-2xl font-ebGaramond mb-2">
                Your Registered Office Address
              </p>
              {AddressData ? (
                <div className="text-gray-400">
                  <p className="text-sm">Address Line 1:</p>
                  <p> {AddressData?.address_line_1}</p>
                  <p className="text-sm">Address Line 2:</p>
                  <p>{AddressData?.address_line_2}</p>
                  <p className="text-sm">Postal Code:</p>
                  <p> {AddressData?.postal_code}</p>
                  <p className="text-sm">Country:</p>
                  <p> {AddressData?.country?.name}</p>
                </div>
              ) : (
                <p>No address data available</p>
              )}
            </div>
          </div>
        </div>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={{
          address_type: addressCompanyActivitiesPopup
            ? {
                value: relevantAddress?.address_type,
                label:
                  relevantAddress?.address_type === "commercial"
                    ? "Commercial"
                    : "Residential",
              }
            : addressTypeValue
            ? addressTypeValue
            : "",
          address_line_1: addressCompanyActivitiesPopup
            ? relevantAddress?.address?.address_line_1 || ""
            : addresses?.length && addresses[0]?.address?.address_line_1
            ? addresses[0]?.address?.address_line_1
            : "",
          address_line_2: addressCompanyActivitiesPopup
            ? relevantAddress?.address?.address_line_2 || ""
            : addresses?.length && addresses[0]?.address?.address_line_2
            ? addresses[0]?.address?.address_line_2
            : "",
          postal_code: addressCompanyActivitiesPopup
            ? relevantAddress?.address?.postal_code || ""
            : addresses?.length && addresses[0]?.address?.postal_code
            ? addresses[0]?.address?.postal_code
            : "",
          countryId: addressCompanyActivitiesPopup
            ? relevantAddress?.address?.country_id || ""
            : addresses?.length && addresses[0]?.address?.country_id
            ? addresses[0]?.address?.country_id
            : "",

          physical:
            addressCompanyActivitiesPopup && relevantAddress
              ? relevantAddress?.physical_store === 0
                ? "false"
                : "true"
              : addresses?.length && addresses[0]?.physical_store === 0
              ? false
              : true,
          store_address:
            addresses?.length &&
            addresses[0]?.address?.address_line_1 ===
              addresses[0]?.physical_store_address?.address_line_1
              ? true
              : false,
          physical_store_address: {
            same_as_above:
              addresses?.length &&
              addresses[0]?.address?.address_line_1 ===
                addresses[0]?.physical_store_address?.address_line_1
                ? true
                : false,
            address_line_1: addressCompanyActivitiesPopup
              ? relevantAddress?.physical_store_address?.address_line_1 || ""
              : addresses?.length &&
                addresses[0]?.physical_store_address?.address_line_1
              ? addresses[0]?.physical_store_address?.address_line_1
              : "",
            address_line_2: addressCompanyActivitiesPopup
              ? relevantAddress?.physical_store_address?.address_line_2 || ""
              : addresses?.length &&
                addresses[0]?.physical_store_address?.address_line_2
              ? addresses[0]?.physical_store_address?.address_line_2
              : "",
            postal_code: addressCompanyActivitiesPopup
              ? relevantAddress?.physical_store_address?.postal_code || ""
              : addresses?.length &&
                addresses[0]?.physical_store_address?.postal_code
              ? addresses[0]?.physical_store_address?.postal_code
              : "",
            countryId: addressCompanyActivitiesPopup
              ? relevantAddress?.physical_store_address?.country_id || ""
              : addresses?.length &&
                addresses[0]?.physical_store_address?.country_id
              ? addresses[0]?.physical_store_address?.country_id
              : "",
          },
        }}
        validationSchema={Yup.object().shape({
          address_type: Yup.object()
            .shape({
              value: Yup.string().required("Please Select Address Type"),
              label: Yup.string().required("Please Select Address Type"),
            })
            .nullable()
            .required("Please Select Address Type"),
          address_line_1: Yup.string().required("Please Enter Address Line 1"),
          address_line_2: Yup.string().nullable(),
          postal_code: Yup.string()
            .required("Please Enter Postal Code")
            .length(5, "Postal Code must be exactly 5 characters")
            .matches(/^\d+$/, "Postal Code must be numeric"),
          countryId: Yup.string()
            .nullable()
            .required("Please select a country"),
          physical: Yup.boolean()
            .required("Please Select Do you operate a physical store")
            .oneOf(
              [true, false],
              "Please Select Do you operate a physical store"
            ),
          physical_store_address: Yup.object().when("physical", {
            is: true,
            then: Yup.object().shape({
              address_line_1: Yup.string().required(
                "Please Enter Address Line 1"
              ),
              postal_code: Yup.string()
                .required("Please Enter Postal Code")
                .length(5, "Postal Code must be exactly 5 characters")
                .matches(/^\d+$/, "Postal Code must be numeric"),
              countryId: Yup.string()
                .nullable()
                .required("Please Select Country"),
            }),
            otherwise: Yup.object().notRequired(),
          }),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true);
          let type = "registered";

          dispatch(
            createAddress({
              company_id: companyId,
              type,
              details: [
                {
                  sub_type: "business_operational",
                  address_type: values.address_type?.value,
                  address_line_1: values.address_line_1,
                  address_line_2: values.address_line_2 || "",
                  postal_code: values.postal_code,
                  country_id: values.countryId,
                  physical_store: values.physical || false,
                  physical_store_address: values.physical
                    ? {
                        same_as_above: values.store_address || false,
                        address_line_1: values.store_address
                          ? values.address_line_1
                          : values.physical_store_address.address_line_1 || "",
                        address_line_2: values.store_address
                          ? values.address_line_2
                          : values.physical_store_address.address_line_2 || "",
                        postal_code: values.store_address
                          ? values.postal_code
                          : values.physical_store_address.postal_code,
                        country_id: values.store_address
                          ? values.countryId
                          : values.physical_store_address.countryId,
                      }
                    : null,
                },
              ],
            })
          )
            .then((res) => {
              setLoading(false);
              if (res.type === "createAddress/fulfilled") {
                {
                  addressCompanyActivitiesPopup
                    ? setAddressCompanyActivitiesPopup(false)
                    : next();
                }
                dispatch(getCompanyById(companyId));
              }
              setSubmitting(false);
            })
            .catch((error) => {
              setSubmitting(false);
              console.error(error);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <>
              <form onSubmit={handleSubmit}>
                <div className="border-b border-secondary_grey space-y-3 pb-3">
                  <p className="mb-3">2. Business operations address</p>
                  <div className="text-sm">
                    <p>
                      A physical location that you are conducting business
                      activities from.
                    </p>
                    <p>
                      You won't be able to use Sleek's registered address as
                      your business address.
                    </p>
                  </div>
                  <Link
                    target="_blank"
                    to={
                      "https://www.acra.gov.sg/how-to-guides/setting-up-a-local-company/registered-office-address"
                    }
                    className="text-theme border-b border-theme inline-block"
                  >
                    Eligibility Criteria
                  </Link>
                  <div>
                    <ReactSelect
                      className={"capitalize p-0"}
                      id="address_type"
                      placeholder="Address Type"
                      options={[
                        { value: "residental", label: "Residential" },
                        { value: "commercial", label: "Commercial" },
                      ]}
                      isLoading={false}
                      onChange={(selectedOption) => {
                        setFieldValue("address_type", selectedOption);
                      }}
                      value={values.address_type}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.address_type && touched.address_type
                              ? "red"
                              : "#cdddeb"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #cdddeb",
                          },
                        }),
                      }}
                    />
                    {errors.address_type && touched.address_type ? (
                      <>
                        <InputError errorTitle={errors.address_type} />
                      </>
                    ) : null}
                  </div>
                  <InputWithLabel
                    label={"Address Line 1"}
                    type={"text"}
                    className={
                      errors.address_line_1 && touched.address_line_1
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="address_line_1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_line_1}
                    errors={errors.address_line_1 && touched.address_line_1}
                    errorsText={errors.address_line_1}
                  />
                  <InputWithLabel
                    label={"Address Line 2"}
                    type={"text"}
                    className="border"
                    idFromName="address_line_2"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_line_2}
                  />
                  <div className="grid grid-cols-2 gap-5">
                    <InputWithLabel
                      label={"Postal Code"}
                      type="number"
                      idFromName={"postal_code"}
                      className={
                        errors.postal_code && touched.postal_code
                          ? ` input-error `
                          : ` border `
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.postal_code}
                      errors={errors.postal_code && touched.postal_code}
                      errorsText={errors.postal_code}
                    />
                    <div>
                      <ReactSelect
                        className={"text-black p-0"}
                        id="countryId"
                        placeholder="Country"
                        options={countryArray}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        value={
                          countryArray?.length > 0 &&
                          countryArray.find((op) => op.id === values.countryId)
                        }
                        onChange={(e) => {
                          setFieldValue("country", e);
                          setFieldValue("countryId", e?.id);
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: `1px solid ${
                              errors.countryId && touched.countryId
                                ? "red"
                                : "#cdddeb"
                            }`,
                            borderRadius: "0.25rem",
                            padding: "6px 4px",
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #cdddeb",
                            },
                          }),
                        }}
                      />

                      {errors.countryId && touched.countryId ? (
                        <>
                          <InputError errorTitle={errors.countryId} />
                        </>
                      ) : null}
                    </div>
                  </div>
                  <p className="text-sm">Do you operate a physical store?</p>
                  <RadioButton
                    label={"No"}
                    type="radio"
                    id="physical_no"
                    name="physical"
                    value={false}
                    checked={values.physical === false}
                    onChange={() => setFieldValue("physical", false)}
                    onBlur={handleBlur}
                  />
                  <RadioButton
                    label={"Yes"}
                    type="radio"
                    id="physical_yes"
                    name="physical"
                    value={true}
                    checked={values.physical === true}
                    onChange={() => setFieldValue("physical", true)}
                    onBlur={handleBlur}
                  />
                  {values.physical === true && (
                    <div className="space-y-3 px-6">
                      <div>
                        <label
                          className="flex items-center"
                          htmlFor="store_address"
                        >
                          <input
                            id="store_address"
                            type="checkbox"
                            name="store_address"
                            value={values.store_address}
                            checked={values.store_address}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              setFieldValue("store_address", checked);

                              if (checked) {
                                setFieldValue(
                                  "physical_store_address.address_line_1",
                                  values.address_line_1
                                );
                                setFieldValue(
                                  "physical_store_address.address_line_2",
                                  values.address_line_2
                                );
                                setFieldValue(
                                  "physical_store_address.postal_code",
                                  values.postal_code
                                );
                                setFieldValue(
                                  "physical_store_address.countryId",
                                  values.countryId
                                );
                              } else {
                                setFieldValue(
                                  "physical_store_address.address_line_1",
                                  ""
                                );
                                setFieldValue(
                                  "physical_store_address.address_line_2",
                                  ""
                                );
                                setFieldValue(
                                  "physical_store_address.postal_code",
                                  ""
                                );
                                setFieldValue(
                                  "physical_store_address.countryId",
                                  null
                                );
                              }
                            }}
                            className="w-4 h-4 mr-2"
                          />
                          My store address is the same as the above
                        </label>
                        {errors.store_address && touched.store_address ? (
                          <InputError errorTitle={errors.store_address} />
                        ) : null}
                      </div>
                      <InputWithLabel
                        label={"Address Line 1"}
                        type={"text"}
                        className={`
                                    ${
                                      values.store_address
                                        ? "text-gray-500"
                                        : ""
                                    } 
                                    ${
                                      values.store_address
                                        ? errors.address_line_1
                                          ? "input-error"
                                          : "border"
                                        : errors.physical_store_address
                                            ?.address_line_1 &&
                                          touched.physical_store_address
                                            ?.address_line_1
                                        ? "input-error"
                                        : "border"
                                    }
                                    `}
                        idFromName="physical_store_address.address_line_1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values.store_address
                            ? values.address_line_1
                            : values.physical_store_address?.address_line_1
                        }
                        errors={
                          values.store_address
                            ? errors.address_line_1
                            : errors.physical_store_address?.address_line_1 &&
                              touched.physical_store_address?.address_line_1
                        }
                        errorsText={
                          values.store_address
                            ? errors.address_line_1
                            : errors.physical_store_address?.address_line_1
                        }
                        disabled={values.store_address}
                      />
                      <InputWithLabel
                        label={"Address Line 2"}
                        type={"text"}
                        className={`
                                    ${
                                      values.store_address
                                        ? "text-gray-500"
                                        : ""
                                    } 
                                     ${
                                       errors.physical_store_address
                                         ?.address_line_2 &&
                                       touched.physical_store_address
                                         ?.address_line_2
                                         ? "input-error"
                                         : "border"
                                     }
                                    `}
                        idFromName="physical_store_address.address_line_2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values.store_address
                            ? values.address_line_2
                            : values.physical_store_address?.address_line_2
                        }
                        errors={
                          errors.physical_store_address?.address_line_2 &&
                          touched.physical_store_address?.address_line_2
                        }
                        errorsText={
                          errors.physical_store_address?.address_line_2
                        }
                        disabled={values.store_address}
                      />
                      <div className="grid grid-cols-2 gap-5">
                        <InputWithLabel
                          label={"Postal Code"}
                          type="number"
                          idFromName="physical_store_address.postal_code"
                          className={`
                                      ${
                                        values.store_address
                                          ? "text-gray-500"
                                          : ""
                                      } 
                                      ${
                                        values.store_address
                                          ? errors.postal_code
                                            ? "input-error"
                                            : "border"
                                          : errors.physical_store_address
                                              ?.postal_code &&
                                            touched.physical_store_address
                                              ?.postal_code
                                          ? "input-error"
                                          : "border"
                                      }
                                      `}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={
                            values.store_address
                              ? values.postal_code
                              : values.physical_store_address?.postal_code
                          }
                          errors={
                            values.store_address
                              ? errors.postal_code
                              : errors.physical_store_address?.postal_code &&
                                touched.physical_store_address?.postal_code
                          }
                          errorsText={
                            values.store_address
                              ? errors.postal_code
                              : errors.physical_store_address?.postal_code
                          }
                          disabled={values.store_address}
                        />
                        <div>
                          <ReactSelect
                            className={"text-black p-0"}
                            id="physical_store_country"
                            placeholder="Country"
                            options={countryArray}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            isDisabled={values.store_address}
                            value={countryArray.find((op) =>
                              values.store_address
                                ? op.id === values.countryId
                                : op.id ===
                                  values.physical_store_address?.countryId
                            )}
                            onChange={(e) =>
                              setFieldValue(
                                "physical_store_address.countryId",
                                e?.id
                              )
                            }
                            styles={{
                              control: (base) => ({
                                ...base,
                                border: `1px solid ${
                                  values.store_address
                                    ? errors.countryId
                                      ? "red"
                                      : "#cdddeb"
                                    : errors.physical_store_address
                                        ?.countryId &&
                                      touched.physical_store_address?.countryId
                                    ? "red"
                                    : "#cdddeb"
                                }`,
                                borderRadius: "0.25rem",
                                padding: "6px 4px",
                                boxShadow: "none",
                                "&:hover": {
                                  border: "1px solid #cdddeb",
                                },
                              }),
                            }}
                          />
                          {values.store_address ? (
                            errors.countryId ? (
                              <InputError errorTitle={errors.countryId} />
                            ) : null
                          ) : errors.physical_store_address?.countryId &&
                            touched.physical_store_address?.countryId ? (
                            <InputError
                              errorTitle={
                                errors.physical_store_address.countryId
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {addressCompanyActivitiesPopup ? (
                  <div className="flex justify-between gap-2 py-3">
                    <button
                      type="button"
                      className="btn-outline flex items-center space-x-3 uppercase w-full justify-center"
                      onClick={() => setAddressCompanyActivitiesPopup(false)}
                    >
                      <span>CANCEl</span>
                    </button>
                    <button
                      type="submit"
                      className="btn-theme flex items-center space-x-3 uppercase w-full justify-center"
                      disabled={loading}
                    >
                      {loading ? <ButtonLoader /> : <span>Save</span>}
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-end gap-4 py-3">
                    <button
                      type="button"
                      className="btn-outline flex items-center space-x-3 uppercase"
                      onClick={() => prev()}
                    >
                      <BackArrow className="fill-theme" fillOpacity={1} />
                      <span>Back</span>
                    </button>
                    <button
                      type="submit"
                      className="btn-theme flex items-center space-x-3 uppercase"
                      disabled={loading}
                    >
                      {loading ? (
                        <ButtonLoader />
                      ) : (
                        <span>Save & Proceed On</span>
                      )}
                      <ArrowForward />
                    </button>
                  </div>
                )}
              </form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default RegisteredOfficeAddress;
